<!-- 上传图片、视频 -->
<template>
    <div>
         <h1 class="title">上传图片:</h1>
        <van-field class="titleImg" readonly input-align="right">
          <template #button>
            <div style="display: flex;">
              <div class="avatar" style="width:80px;height:80px;margin-right:20px;">
                <CropperH5 ref="avatarImage" :fixedNumber="[1,1]" @getImageUrl='getAvatarImageUrl1' :image='avatarImage' />
              </div>
              <div class="avatar" style="width:80px;height:80px;">
                <CropperH5 ref="avatarImage" :fixedNumber="[1,1]" @getImageUrl='getAvatarImageUrl2' :image='avatarImage' />
              </div>
            </div>
          </template>
        </van-field>
    </div>
</template>
       
<script>
import CropperH5 from './laji.vue'
export default {
    data() {
    return {
      avatarImage: "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=319223871,3949806150&fm=26&gp=0.jpg",
      img1: '',
      img2: ''
    }
  },
  components: {
       CropperH5
    },
methods:{
//获取头像url
    async getAvatarImageUrl1(file) {
      var formData = new FormData();
      formData.append('file', file); // 固定格式
      const res = await upload(formData)
      if (res) {
        this.img1 = (res.data[0])
        console.log(res);
      }
    },
    async getAvatarImageUrl2(file) {
      var formData = new FormData();
      formData.append('file', file); // 固定格式
      const res = await upload(formData)
      if (res) {
        this.img2 = (res.data[0])
        console.log(res);
      }
    },
}

}
</script>