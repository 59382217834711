import { render, staticRenderFns } from "./yueOder.vue?vue&type=template&id=36fc6ce4&scoped=true&"
import script from "./yueOder.vue?vue&type=script&lang=js&"
export * from "./yueOder.vue?vue&type=script&lang=js&"
import style0 from "./yueOder.vue?vue&type=style&index=0&id=36fc6ce4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36fc6ce4",
  null
  
)

export default component.exports