<template>
    <div element-loading-text="正在上传图像" v-loading.fullscreen.lock="loading" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div style="background-color:#fff;position: fixed;top: 0;left: 0;width: 100%;height: 100%;">
            <van-row style="margin-top:10px;">
                <van-col span="8" style="text-align: center;line-height: 100px;">上传头像</van-col>
                <van-col span="8">
                    <van-image width="100" height="100" :src="img" @click='uploadimg' />
                    <!-- <van-uploader :after-read="afterRead" /> -->
                </van-col>
                <van-col span="8"></van-col>
            </van-row>
            <div v-if="img" style="text-align: center;margin-top:10%;">
                <van-button type="danger" @click="cancelface" style="margin-right: 10%;" size="small">取消</van-button>
                <van-button type="primary" @click="uplodaface" size="small"  v-if="isw()">上传头像</van-button>
            </div>
        </div>
        <div :style="show2 == '' ? aa : bb"
            style='position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,.7);'>
            <div class="cropper"
                style="position: relative;overflow: hidden; text-align: center;width:100%;height:100%;">
                <!-- <van-image :src="img" class="img"
                    style="position: absolute;width: 100%;height:auto;left: 0;top: 10%;" /> -->
                <van-image :src="img" class="img" style="position: absolute;width: 100%;height:100%;left: 0;top: 0;" />
                <div v-if="!img"
                    style="position:relative;position: absolute;width: 100%;height:100%;z-index: 99;padding-top: 55%;background-color: #fff;"
                    @click="show2 = ''">
                    <van-button class="cpbtn" icon="arrow-left" type="info" style="height:30px;">返回上传头像页面</van-button>
                </div>
                <h5-cropper @getbase64="getbase64Data" :option="option" @get-file="getFile"></h5-cropper>
            </div>
        </div>
    </div>
    <!--  -->
</template>
<!-- 预约下单后，要把这个人授权到人脸机,刷脸进 -->
<script>
import Vue from 'vue';
import { Image as VanImage, Uploader, Col, Row, ImagePreview } from 'vant';
Vue.use(VanImage); Vue.use(Uploader); Vue.use(Col);
Vue.use(Row);
import H5Cropper from "vue-cropper-h5";
export default {
    name: 'userface',
    components: {
        H5Cropper
    },
    data: () => {
        return {
            aa: { 'z-index': '-1', 'opacity': 0 },
            bb: { 'z-index': '3999', 'opacity': 1 },
            obj: {
                username: '',
                cret: '',
                photo: [],
                base64: '',
                idType: '',
                idTypeName: ''
            },
            show2: false,
            loading: false,
            option: {
                //         ceilbutton: true,//操作按钮是否在顶部    
                // // fixedNumber: this.fixedNumber,//截图框的宽高比例    
                // canMoveBox: true,//截图框能否拖动  
                fixedBox: false,//固定截图框大小 不允许改变 
                // centerBox: true,//截图框是否被限制在图片里面 
                fixed: false,//是否开启截图框宽高固定比例    ,
                // img: 'http://geren.yi-school.top/images/logo.png', // 裁剪图片的地址
                // info: true, // 裁剪框的大小信息
                // outputSize: 0.3, // 剪切后的图片质量（0.1-1）
                // full: true, // 输出原图比例截图 props名full
                // outputType: 'jpg', // 裁剪生成额图片的格式
                canMove: false,  // 能否拖动图片
                // original: false,  // 上传图片是否显示原始宽高
                canMoveBox: true,  // 能否拖动截图框
                // canMove:true,
                // autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 180,
                autoCropHeight: 180,
                // centerBox:true,
                // fixedBox: false // 截图框固定大小
            },
            img: "",
            avatarImage: "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=319223871,3949806150&fm=26&gp=0.jpg",
            form: '',
            timer: ''
        }
    },
    mounted() {
        let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        if (user) {
            let { memberPic } = user;
            console.log(memberPic)
            let c = this.imgc(memberPic)
            console.log(c)
            this.img = c;
        } else {
            this.getMemeberDetails();
        }
    },
    methods: {
        isw(){
            return this.img.indexOf('/v1/OpenApi/PullImage')>=0 ?false :true
            // return true
        },
        getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid') || ''
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberPic } = res.data;
                        console.log(memberPic)
                        let c = this.imgc(memberPic)
                        console.log(c)
                        this.img = c;
                    } else {
                        localStorage.setItem('user', '')
                    }
                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        imgc(url) {
            console.log(url)
            let urlc =
                window.g.baseUrl +
                "/api/v1/OpenApi/PullImage?PicName=" +
                url +
                "&PathType=1";
            // console.log(urlc);
            return urlc;
        },
        uploadimg() {
            if (!this.img) {
                this.show2 = true;
                let aaa = document.getElementsByClassName('upbtn')[1]
                aaa.click();
                console.log('2222,', aaa)
            } else {
                ImagePreview([this.img]);
            }
        },
        getFile() {
            console.log(3412434)
            this.option.img = ''
        },
        getbase64Data(data) {
            // console.log(3412434)
            console.log('生成了数据')
            this.obj.base64 = data;
            this.obj.photo = [];
            this.img = data;
            this.obj.photo.push({ content: data });
            this.show2 = false;
            console.log(this.show2)
        },
        uplodaface() {
            this.loading = true;
            console.log(this.obj)
            let userJson = localStorage.getItem('user');
            let user = userJson && JSON.parse(userJson)
            let datac = {};
            datac.facePic = this.obj.base64 ? this.obj.base64.split(",")[1] : '';
            datac.memberID = user.id;
            datac.merchantID = localStorage.getItem('MerchantID')
            console.log(datac)
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/WeChat/UploadMemberFacePic",
                    data: {
                        ...datac
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        console.log(res.data);
                        this.$message({
                            type: "success",
                            message: res.data.message || "操作失败",
                        });
                        this.getMemeberDetails()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }

                    this.loading = false;
                }).catch(err => {
                    console.log(err)
                    this.loading = false;
                    this.$message({
                        type: "error",
                        message: err.message || "操作失败",
                    });

                })
        },
        cancelface() {
            this.obj = {},
                this.img = ''
        }
    }
}
</script>

<style>
.cpbtn {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
}
</style>