<template>
    <div style="padding-top: 46px;">
        <van-nav-bar title="订餐" left-text="返回" left-arrow @click-left="onClickLeft" fixed />
        <van-tabs v-model="active" id="eee" title-active-color="#c47c5f" color="#c47c5f" sticky>
            <van-tab title="元气早点" style="margin-top:10px;padding-bottom:50px;" >
                <div>
                    <van-card v-for='index in 7' :key='index' price="2.00" desc="" title="青菜包子"
                        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg" origin-price="10.00">
                        <template #tags>
                            <div style='color:#999;margin:5px 0;'>月销3 推荐</div>

                        </template>
                        <template #footer>
                            <van-stepper v-model="value" theme="round" max="10" button-size="22" disable-input />
                        </template>
                    </van-card>
                    <!-- <span class='empty'>已加载全部创建的订单</span> -->
                </div>
            </van-tab>
            <van-tab title="风味主食">
                <div>
                    <span class='empty'>已加载全部创建的订单</span>
                </div>
            </van-tab>
            <van-tab title="甜品/小食">
                <div>
                    <span class='empty'>已加载全部创建的订单</span>
                </div>
            </van-tab>
            <van-tab title="限定饮品">
                <div>
                    <span class='empty'>已加载全部创建的订单</span>
                </div>
            </van-tab>
        </van-tabs>
        <van-goods-action>
            <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
            <van-goods-action-icon icon="cart-o" badge="9" text="购物车" @click="onClickIcon" />
            <!-- <van-goods-action-icon icon="shop-o" text="店铺" @click="onClickIcon" /> -->
            <van-goods-action-button type="danger" text="立即购买" @click="onClickButton" />
        </van-goods-action>
        <van-action-sheet v-model="show" title="已选择">
            <div class="content">
                <div style="text-align: right;" @click="clear"><van-button class='bb' type="primary" plain>清空</van-button></div>
                <div v-for="index in 6" :key="index"
                    style="height:40px;line-height: 40px;border-bottom: 1px solid #ddd;">
                    <div style="display: flex;justify-content: space-between;">
                        <div style='font-size:0.8rem;'>青菜包子 ￥ 10元</div>
                        <div>
                            <van-stepper v-model="value" theme="round" max="10" button-size="22" disable-input />
                        </div>
                    </div>
                </div>
                <van-goods-action>
                    <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
                    <van-goods-action-icon icon="cart-o" badge="9" text="购物车" @click="onClickIcon" />
                    <!-- <van-goods-action-icon icon="shop-o" text="店铺" @click="onClickIcon" /> -->
                    <van-goods-action-button type="danger" text="立即购买" @click="onClickButton1" />
                </van-goods-action>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import Vue from 'vue';
import { NavBar, GoodsAction, GoodsActionIcon, GoodsActionButton, Toast, TreeSelect, Card, Stepper, ActionSheet, Button } from 'vant';
Vue.use(NavBar);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(TreeSelect);
Vue.use(GoodsActionIcon);
Vue.use(Button);
Vue.use(ActionSheet);
Vue.use(Stepper);
Vue.use(Card);Vue.use(Toast);
export default {
    name: 'dingCan',

    data() {
        return {
            active: 0,
            value: 0,
            show: false
        };
    },

    mounted() {

    },

    methods: {
        clear(){
            Toast('清空');
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
        onClickIcon() {
            Toast('点击图标');
            this.show = true;
        },
        onClickButton() {
            Toast('点击按钮,去下单');
            this.$router.push('/payOrder');
            
        },
        onClickButton1() {
            Toast('点击按钮,去下单');   
            this.show = false;  
            this.$router.push('/payOrder');       
        },
    },
};
</script>
<style  scoped>
button.bb{ padding: 0 10px;
    height: 26px;color:#999;border:none;
    line-height: 26px;}
button.aa {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    background-color: #f26a40;
    border-color: #f26a40;
}

.content {
    padding: 6px 16px 56px;
}
</style>