<template>
    <div style="width: 90%;margin-left: 5%">
        <div class="title">{{ticketName}}</div>
        <el-divider></el-divider>
        <div class="title">购票须知</div>
        <span class="detail">{{reserve6}}</span>
        <el-divider></el-divider>
        <div class="title">使用说明</div>
        <span  class="detail">{{reserve7}}</span>
        <el-divider></el-divider>
        <div class="title">其他说明</div>
        <span  class="detail">{{reserve8}}</span>
    </div>

</template>

<script>
    import router from "@/router";

    export default {
        name: "TicketDesc",
        data: () => {
            return {
                ticketName:'',
                reserve6:'',
                reserve7:'',
                reserve8:'',
            }
        },
        created() {
            this.ticketName = router.currentRoute.params.ticketName
            this.reserve6 = router.currentRoute.params.reserve6
            this.reserve7 = router.currentRoute.params.reserve7
            this.reserve8 = router.currentRoute.params.reserve8
        },
        methodss:{

        }
    }
</script>

<style scoped>

    .title{
        font-weight: bold;
        font-size: 16px;
    }
</style>