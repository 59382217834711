<template>
    <div>
        <div style="margin:20px 15px;padding-bottom:50px;">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
                <!-- <van-list v-model="loading" :finished="finished" finished-text="暂时没有可预约的项目" @load="onLoad"> -->
                <van-card  style="background-color: #fff;"
                          v-for="item in sportProjects" :key='item.id' :title="item.sportProjectName"
                           :thumb="item.sportProjectPic1" @click-thumb="toTicketDesc(item)">
                    <template #price>
                        <div style="font-size: 17px;color: red">￥{{item.wxAmount}}</div>

                    </template>
                    <template #tags>
                        <div style="margin: 5px 0 ;color: #777;">{{item.billingMethod == 0 ? '计时票' : '计次票'}}</div>
                        <div style="color: #777;">景点名称：{{ item.siteName }}</div>
                    </template>
                    <template #footer >
                        <van-stepper v-model="item.count" min="0" theme="round" style="margin-top: -30px" @change="caculate(item)"/>
                    </template>
                </van-card>
                <!-- </van-list> -->
                <van-empty v-if='sportProjects.length == 0' description="暂时没有可预约的项目" />
            </van-pull-refresh>
            <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="nextStep">
<!--                <div style="background-color: red">购票须知</div>-->
                <div style="float: right">已选：{{totalTicket}}张</div>
            </van-submit-bar>
        </div>

    </div>
</template>

<script>

    import CryptoJS from "crypto-js";

    const decrypt = (ciphertext, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrpted = CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return decrpted.toString(CryptoJS.enc.Utf8)
    }
    import Vue from 'vue';
    import { Swipe, NavBar, SwipeItem, TabbarItem, Tab, Tabs, Card, Button, Toast, Popup, Search, PullRefresh, List, Empty } from 'vant';
    import router from "@/router";
    Vue.use(Toast);
    let cAPPID = '';
    let cpay_key='';
    let cAppSecret = '';
    let cmch_id = '';
    export default {
        name: "TicketDetail",
        data: () => {
            return {

                ticketList:[],
                showDatePicker:false,
                form:{},
                action:0,
                price:0,
                totalPrice:0,
                totalTicket:0,
                orderNo:'',
                index3: 0,
                end3: 99,
                PayOptions:[],
                MerchantID: localStorage.getItem("MerchantID"),
                sportProjects:[],
                isLoading:false,
                telRules: [{
                    required: true,
                    message: '请输入手机号码',
                    trigger: 'onBlur'
                }, {
                    // 自定义校验规则
                    validator: value => {
                        return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                            .test(value)
                    },
                    message: '请输入正确格式的手机号码',
                    trigger: 'onBlur'
                }],
                idNoRules:[{
                    required: true,
                    message: '请输入身份证号',
                    trigger: 'onBlur'
                }, {
                    // 自定义校验规则
                    validator: value => {
                        return /^(\d{18}|\d{15}|\d{17}x)$/
                            .test(value)
                    },
                    message: '请输入正确格式的身份证号',
                    trigger: 'onBlur'
                }],
            }
        },
        filters: {
            imgc(url) {
                // console.log(url)
                let urlc =
                    window.g.baseUrl +
                    "/api/v1/OpenApi/PullImage?PicName=" +
                    url +
                    "&PathType=2";
                // console.log(urlc);
                return urlc;
            }
        },
        props: {
            msg: String,
        },
        created() {
            this.getSportProjectInfo()
            this.GetDataDictionaryInfo()
        },
        methods:{
            toTicketDesc(item){
                router.push({name:'TicketDesc', params:{ticketNme: item.sportProjectName, reserve6: item.reserve6, reserve7: item.reserve7, reserve8: item.reserve8}})
            },
            nextStep(){
                if(this.totalTicket == 0) {
                    Toast.fail({duration:1000,message:'请添加票种'})
                    return
                }
                this.onSubmit();
                //router.push({name:'WriteMemberInfo',params: {sportProjects: this.sportProjects,totalTicket: this.totalTicket, totalPrice: this.totalPrice}})
            },
            onSubmit(){
                    this.ticketList = []
                    let netRechargeInfoList = []
                    this.sportProjects.forEach(ele => {
                        if(ele.count == 1){
                            ele.qrcode = this.randomString(30)
                            this.ticketList.push(ele)
                        }else if(ele.count > 1){
                            for(let i = 0; i < ele.count; i++){
                                this.ticketList.push(ele)
                            }
                        }
                    })
                    // let i = 0
                    // this.ticketList.forEach(ele=>{
                    //     i+=1
                    //     let qrcode = this.randomString(30)
                    //     ele.qrcode = i
                    //     console.log(ele.qrcode)
                    //     console.log(ele)
                    // })
                    this.orderNo = this.randomString(30)
                    let netRecharge = {
                        MerchantID: localStorage.getItem("MerchantID"),
                        NetRechargeInfoList: netRechargeInfoList,
                        memberID:localStorage.getItem('openid'),
                        orderNo: this.orderNo,
                        amount: this.totalPrice/100 ,
                        createBy: localStorage.getItem('openid')}
                    this.WriteCustomerNetRechargeInfo(netRecharge)

            },
            WriteCustomerNetRechargeInfo(netRecharge){
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/WriteCustomerNetRechargeInfo",
                        method: "POST",
                        data: { ...netRecharge },
                    })
                    .then((res) => {
                        console.log(res)
                        if(res.data.success){
                            this.WriteCustomerOrderInfo(0,this.dateFormat(), this.orderNo)
                            this.createWXOrder(this.MerchantID, netRecharge.orderNo,this.totalPrice,this.dateFormat())
                            //this.createWXOrder(this.MerchantID, netRecharge.orderNo,this.totalPrice,this.dateFormat())

                        }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            WriteCustomerOrderInfo(syncIndex, payDateTime, getTicketCode){
                let payType = ''
                this.PayOptions.forEach(ele=>{
                    if(ele.dictionaryContent == '微信')
                        payType=ele.id
                })
                let orderInfoList = []
                this.ticketList.forEach(ele => {
                    let orderInfo = {
                        sportProjectID: ele.id,
                        memberID:localStorage.getItem('openid'),
                        payType: payType,
                        orderNo: this.orderNo,
                        orderDate: this.getTime(),
                        orderTime:this.getTime(),
                        amount: ele.wxAmount,
                        orderType: 1,
                        createBy: localStorage.getItem('openid'),
                        memberType: 2,
                        humanCount:1,
                        memberName:'游客',
                        idno:'350821000000000000',
                        phone:'18100000000',
                        qrCode:ele.qrcode,
                        cardNO:'',
                        payTime:this.getTime(),
                        effectiveBegin: '2023-04-01',
                        effectiveEnd: '2055-05-05',
                    }
                    orderInfoList.push(orderInfo)
                })
                let req_order = {syncIndex: syncIndex, payDateTime: payDateTime,payTime: payDateTime, payOrderNo: getTicketCode, payState: syncIndex == 1 ? 0:1, payType:payType, merchantID:localStorage.getItem('MerchantID'),getTicketCode:this.orderNo,orderInfoList:orderInfoList}

                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/WriteCustomerOrderInfo",
                        method: "POST",
                        data: { ...req_order },
                    })
                    .then((res) => {
                        console.log(res.data.success)
                        // if(res.data.success){
                        //     Toast.success({duration:1000,message:'购票成功',onClose:function () {
                        //             router.push({name:'orderDetail'})
                        //         }})
                        //
                        // }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            createWXOrder(MerchantID, OrderNo, Amount, OrderTime) {
                // Toast('第二步，去node服务器生成微信订单，去支付')
                // this.$toast.loading({
                //     message: '第二步，去node服务器生成微信订单，去支付',
                //     forbidClick: true,
                // });
                try {
                    if(Number.parseFloat(Amount) % 1 != 0){
                        Amount = Math.round(Amount)
                    }
                    if(localStorage.getItem('payParameters') == null)
                    {
                        this.GetPaymentParameters()
                    }
                    let payParameters = localStorage.getItem('payParameters')
                    let payParametersJSON = payParameters && JSON.parse(payParameters)
                    cAPPID = payParametersJSON.weChatAppID;
                    cAppSecret = payParametersJSON.weChatKey; //支付密钥
                    cmch_id = payParametersJSON.weChatMchID;
                    cpay_key = payParametersJSON.weChatKey;

                    this.loading = true;
                    this.title = '生成微信订单'
                    let that = this;
                    let local = 'http://43.138.48.29/token/wxresult';
                    //let local = "https://zhy.jiechengan.com:8090/api/v1/OpenApi/payResult"
                    let obj = {
                        appid: cAPPID,
                        mch_id: cmch_id,
                        key: cAppSecret,
                        openid: localStorage.getItem('openid'),
                        timestamp: OrderTime,
                        out_trade_no: that.orderNo,
                        total_fee: Amount, //写死
                        nonceStr: this.randomString(16),
                        body: '公众号购票' + Amount + '元',
                        notify_url: local, //异步回调地址，去通知服务器的地址
                    }
                    this.$axios
                        .request({
                            url: window.g.tokenBaseUrl + "/testwxpay",
                            method: "POST",
                            data: {
                                ...obj
                            },
                        })
                        .then((res) => {
                            function onBridgeReady(res) {
                                // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                                let paySign = that.$md5(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${cpay_key}`).toUpperCase();
                                WeixinJSBridge.invoke(
                                    'getBrandWCPayRequest', {
                                        "appId": cAPPID,     //公众号ID，由商户传入
                                        "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                        "nonceStr": res.nonceStr, //随机串
                                        "package": res.package,
                                        "signType": "MD5",         //微信签名方式：
                                        "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                    },
                                    function (resc) {
                                        if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                            // 使用以上方式判断前端返回,微信团队郑重提示：
                                            // alert('支付成功')
                                            // that.paymentResult(res, resc)
                                            // that.testFindOrder(res, resc)
                                            try{
                                                that.UpdatePayState (res.out_trade_no);
                                                //that.WriteCustomerOrderInfo(0,OrderTime, that.orderNo)
                                            }
                                            catch (e) {
                                                Toast.fail({duration:2000,message:e.message})
                                            }
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                            Toast.fail({duration:1000,message:'已取消'})
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                            Toast.fail({duration:1000,message:'支付失败'})
                                        }
                                        that.value = '';
                                    });
                            }
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                onBridgeReady(res.data.data);
                            }
                            if (res.data.return_msg == 'OK') {
                                // this.wxpayFunc(res, OrderNo, 5.51 * 100);
                            } else {
                            }
                            that.loading = false;
                        });
                }
                catch (e) {
                    Toast.fail({duration:3000,message:e.message})
                }
            },
            UpdatePayState (PayOrderNo){
                this.$axios
                    .request({
                        url: "/api/v1/WXProgram/UpdateCustomerOrderInfo",
                        method: "POST",
                        data: { MerchantID: localStorage.getItem("MerchantID"), PayOrderNo: PayOrderNo, GetTicketCode: this.orderNo },
                    })
                    .then((res) => {
                        console.log(res.data.success)
                        if(res.data.success){
                            Toast.success({duration:1000,message:'购票成功',onClose:function () {
                                    router.push({name:'orderDetail'})
                                }})

                        }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            GetPaymentParameters() {
                    this.$axios
                        .request({
                            method: "GET",
                            url: "/api/v1/OpenApi/GetPaymentParameters",
                            params: {
                                merchantID: localStorage.getItem("MerchantID"),
                            },
                        })
                        .then((res) => {
                            console.log(res.data);
                            let obj = {
                                alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
                                alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
                                alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
                                merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
                                merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
                                weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key),
                                weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key),
                                weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
                                weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
                                weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
                                weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
                            }
                            // console.log({...res.data,...obj});
                            // this.$EventBus.$emit("asd", {
                            //   ...res,
                            //   title: "支付参数",
                            //   ctx: "获取支付参数信息" + "",
                            // });
                            localStorage.setItem('payParameters', JSON.stringify(obj))
                            // cAPPID = obj.weChatAppID;
                            // cAppSecret = obj.weChatKey; //支付密钥
                            // cmch_id = obj.weChatMchID;
                            // this.form = res.data;
                            // this.getCode();
                        });

            },
            caculate(item){
                this.totalTicket = 0
                this.totalPrice = 0
                this.sportProjects.forEach(ele => {
                    this.totalPrice += ele.count * ele.wxAmount * 100
                    if(ele.reserve9 != null && ele.reserve9 !='')
                    {
                        this.totalPrice -= ele.count * ele.reserve9 * 100
                        console.log(this.totalPrice)
                    }
                    this.totalTicket += ele.count
                })

            },
            randomString(e) {
                e = e || 32;
                let t = "0123456789",
                    a = t.length,
                    n = "";
                for (let i = 0; i < e; i++) {
                    n += t.charAt(Math.round(Math.random() * a));
                }
                return n
            },
            onRefresh() {
                setTimeout(() => {
                    Toast('刷新成功');
                    this.isLoading = false;
                    this.count++;
                    this.loading = false
                    this.finished = false
                }, 1000);

            },
            getSportProjectInfo(SiteID = '') {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetGZHSportProjectInfo?Orderby",
                        methods: "get",
                        params: {
                            MerchantID: localStorage.getItem("MerchantID"),
                            SportProjectNo: '',
                            StartDate: '20200101',
                            EndDate: '20500101',
                            StartIndex: 0,
                            EndIndex: 999,
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            res.data.models.forEach(ele => {
                                ele.count = 0
                            })
                            this.sportProjects = res.data.models;
                            //赋值
                        } else {
                            this.sportProjects = []
                            // Toast(res.data.message)
                        }

                    }).catch((err) => {
                    Toast(err)
                })
            },
            guid() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }
                return S4() + S4() + S4() + S4() + S4() + S4();
            },
            dateFormat() {
                var datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month =
                    datec.getMonth() + 1 < 10
                        ? "0" + (datec.getMonth() + 1)
                        : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                var hours =
                    datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
                var minutes =
                    datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
                var seconds =
                    datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
                // 拼接
                return ''+year + month + day + hours + minutes + seconds;
            },
            getTime() {
                Date.prototype.Format = function(fmt) {
                    var o = {
                        "M+": this.getMonth() + 1, //月份
                        "d+": this.getDate(), //日
                        "H+": this.getHours(), //小时
                        "m+": this.getMinutes(), //分
                        "s+": this.getSeconds(), //秒
                        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                        S: this.getMilliseconds() //毫秒
                    };
                    if (/(y+)/.test(fmt))
                        fmt = fmt.replace(
                            RegExp.$1,
                            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                        );
                    for (var k in o)
                        if (new RegExp("(" + k + ")").test(fmt))
                            fmt = fmt.replace(
                                RegExp.$1,
                                RegExp.$1.length == 1
                                    ? o[k]
                                    : ("00" + o[k]).substr(("" + o[k]).length)
                            );
                    return fmt;
                };

                var a = new Date().Format("yyyy-MM-dd HH:mm:ss");

                return (this.nowtime = a);
            },
            GetDataDictionaryInfo() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                        methods: "get",
                        params: {
                            node: 999,
                            MerchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let data = res.data.models;
                            data.forEach((item) => {if (item.parentNode == 10) {
                                    this.PayOptions.push(item);
                                }
                            });
                        }
                    });
            },
        }
    }
</script>

<style scoped>

</style>