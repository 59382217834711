<template>
  <div class="hello">
    <!-- <van-nav-bar title="在线订场" fixed /> -->
    <div class="body">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" lazy-render>
        <van-swipe-item v-for="item in imgs" :key="item">
          <img style="height:200px;width: 100%;" fit="contain" :src="item" alt="">
        </van-swipe-item>
      </van-swipe>
      <div style="width: 100%;height: 60px;margin-top: 10px">
        <van-icon name="location" style="font-size: 16px;font-weight: bolder;margin-left: 10%">&nbsp;虎纠萌趣亲子乐园                          </van-icon>
        <van-icon name="phone" style="margin-top: 5px;margin-left: 10%">&nbsp;19118423739</van-icon>
      </div>
      <div style="width: 100%;height: 5px;background-color: #f3f5f4"></div>
      <div style="margin-top: 10px;width: 100%;height: 80px;display: flex;justify-content: center">
        <div style="width: 86%;height: 100%;display: flex;justify-content: center;background-color: #feeeef">
          <div style="width: 43%;border-right: dashed 1px #f3f5f4;display:flex;justify-content: center;align-items: center;">
            <div><p style="font-size: 15px;font-weight: bolder;color: red">入园时间</p><p style="font-size: 14px">9:00-22:00</p></div>
          </div>
          <div style="width: 43%;height: 100%;display:flex;justify-content: center;align-items: center;">
            <div><p style="font-size: 15px;font-weight: bolder;color: red">开园时间</p><p style="font-size: 14px">9:00-22:00</p></div>
          </div>
        </div>

      </div>
      <div style="width: 100%;height: 30px;display: flex;justify-content: center">
      <div style="width: 86%;height: 100%;display: flex;justify-content: center;background-color: #feeeef;font-size: 18px;font-weight: bolder;color: red">
        园内荷载人数1800人
      </div>
      </div>
      <div style="width: 100%;height: 50px;margin-top: 20px;display: flex;justify-content: center">
        <button style="width: 86%;height: 40px;color: white;font-size: 16px;border-radius: 15px;background-color: red;border: none" @click="ToTicketDetail">门票购买</button>
      </div>
    </div>
    <div style="width: 80%;height: 900px;margin-left: 10%;margin-top: 10px">
      <van-icon name="star" color="red" style="font-size: 16px;font-weight: bolder">&nbsp;&nbsp;预定须知：</van-icon>
      <p>无需预约，4月18日开始使用，高峰期需要排队</p>
      <p>售卖时间：4月9日-6月30日</p>
      <p>购买后有效期：4月18日-6月30日</p>
      <p>营业时间：08:00-22:00</p>
        <p>小火车工作日不运行</p>
      <p>儿童票（身高一米至一米四的儿童使用，购儿童票入园者至少需要一名成年人陪同监护，陪同者需购单人票）</p>
      <p>单人票（一名成人使用，或一名一米四以上的儿童使用）</p>
      <p>双人票（两名成人使用，或一名成人陪同一名一米四以上的儿童使用）</p>
      <p>亲子三人票（两名成人陪同一名一米四以上的儿童使用）</p>
      <p>家庭四人票（两名成人陪同两名一米四以上的儿童使用，或三名成人陪同一名一米四以上的儿童使用）</p>
      <p>每位儿童入园至少需要一名成年人陪同监护</p>
      <p>1米及以下儿童免票，一名成人仅限带一名1米及以下儿童入园</p>
      <van-icon name="star" color="red" style="font-size: 16px;font-weight: bolder">&nbsp;&nbsp;乐园介绍：</van-icon>
      <p>欢迎来到福州市区大型无动力亲子乐园！园区左邻动物园，右邻儿童公园，上邻森林公园。乐园内含30余款网红项目：彩虹滑道、天使滑翔等，全天无限畅玩！还有特色农趣项目“共享菜园”，融入自然体验种植乐趣。</p>
    </div>
    <van-tabbar v-model="activeTab" active-color="red" @change="ChangeTab">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o">会员卡</van-tabbar-item>
      <van-tabbar-item icon="setting-o">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// import { Toast } from 'vant';
import Vue from 'vue';
import { Swipe, NavBar, SwipeItem, TabbarItem, Tab, Tabs, Card, Button, Toast, Popup, Search, PullRefresh, List, Empty } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(TabbarItem); Vue.use(Search); Vue.use(List);
Vue.use(Tab); Vue.use(Empty);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Card);
Vue.use(Button);
Vue.use(Popup); Vue.use(Toast); Vue.use(PullRefresh);
import top5 from "../../public/01.png"
import top4 from "../../public/02.png"
import top3 from "../../public/03.png"
import top2 from "../../public/04.png"
import router from "@/router";
export default {
  name: 'HelloWorld',
  data: () => {
    return {
      activeTab: 0,
      icon: {
        active: 'https://img.yzcdn.cn/vant/user-active.png',
        inactive: 'https://img.yzcdn.cn/vant/user-inactive.png'
      },
      showc: false,
      imgs: [top5, top4, top3, top2],
      titles: [],
      active: 0,
      index1: 0,
      end1: 99,
      index2: 0,
      end2: 99,
      index3: 0,
      end3: 99,
      count: 0,
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      areas: [],
      sites: [],
      sportProjects: [],
      value: '',
    }
  },
  filters: {
    imgc(url) {
      // console.log(url)
      let urlc =
        window.g.baseUrl +
        "/api/v1/OpenApi/PullImage?PicName=" +
        url +
        "&PathType=2";
      // console.log(urlc);
      return urlc;
    }
  },
  props: {
    msg: String,
  },
  mounted() {
    //
    // let mer = this.getUrlParam('MerchantID') || this.getUrlParam('MerchantID')
    // console.log(mer)
    // console.log(window.location)
    // let url = window.location.origin + window.location.pathname + window.location.search + ' /hash'
    // console.log(url)
    // // localStorage.setItem('openid', "oyLwT5761otUw6q2XY28wi_sjX_8")
    // if (!localStorage.getItem('MerchantID')) {
      //localStorage.setItem('MerchantID', this.getUrlParam('MerchantID'))
    // } else {
    //   localStorage.setItem('MerchantID', mer.replace('/', ''))
    // }
    // this.GetDataDictionaryInfo();
    // this.refresh();
  },
  methods: {
    GetDataDictionaryInfo() {
      this.$axios
        .request({
          url: "/api/v1/OpenApi/GetDataDictionaryInfo",
          method: "get",
          params: {
            node: 999,
            MerchantID: localStorage.getItem("MerchantID"),
          },
        })
        .then((res) => {
          // this.$EventBus.$emit('asd',res)
          console.log(res);
          if (res.data.success) {
            localStorage.setItem("DictionaryInfo", JSON.stringify(res.data.models))
          } else {

            // this.$message.error(res.data.message);
          }
        });
    },
    getUrlParam(name) {//封装方法
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象

      var r = window.location.search.substr(1).match(reg); //匹配目标参数

      if (r != null) return unescape(r[2]);

      return null; //返回参数值

    },
    getAreaInfo() {
      this.$axios
        .request({
          url: "/api/v1/WeChat/GetAreaInfo?Orderby",
          methods: "get",
          params: {
            merchantID: localStorage.getItem('MerchantID'),
            StartIndex: this.index1,
            EndIndex: this.end1
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            //赋值     
            this.areas = res.data.models;
            if (res.data.models.length > 0) {
              // this.areas = res.data.models.filter(ele => ele.areaName != '默认区域信息')
              this.sites = [];
             
              this.getSiteInfo(res.data.models[0].id)
              this.selImgs(res.data.models[0].id)
            }
          } else {
            this.areas = [];
            this.sites = [];
            this.sportProjects = [];
            // Toast(res.data.message)
          }

        }).catch((err) => {
          Toast(err)
        })
    },
    getSiteInfo(AreaID) {
      this.$axios
        .request({
          url: "/api/v1/WeChat/GetSiteInfo?Orderby",
          methods: "get",
          params: {
            AreaID: AreaID,
            merchantID: localStorage.getItem('MerchantID'),
            SiteNo: '',
            StartIndex: this.index2,
            EndIndex: this.end2
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            //赋值        
            this.sites = res.data.models;
            this.sportProjects = [];lo
            if (res.data.models.length > 0) {              
              this.getSportProjectInfo(res.data.models[0].id)
            }

          } else {
            this.sites = [];
            this.sportProjects = [];
            // Toast(res.data.message)
          }

        }).catch((err) => {
          Toast(err)
        })
    },
    getSportProjectInfo(SiteID = '') {
      this.$axios
        .request({
          url: "/api/v1/WeChat/GetSportProjectInfo?Orderby",
          methods: "get",
          params: {
            merchantID: localStorage.getItem('MerchantID'),
            SiteID: SiteID,
            StartIndex: this.index3,
            SportProjectNo: '',
            EndIndex: this.end3
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.sportProjects = res.data.models;
            //赋值        
          } else {
            this.sportProjects = []
            // Toast(res.data.message)
          }

        }).catch((err) => {
          Toast(err)
        })
    },
    ToTicketDetail(){
      this.$router.push({ path: '/TicketDetail'});
    },
    ChangeTab(){
      console.log(this.activeTab)
      if(this.activeTab == 0){
        router.push({name:'HomeView'})
      }
      if(this.activeTab == 1){
        router.push({name:'会员卡'})
      }
      if(this.activeTab == 2){
        router.push({name:'个人中心'})
      }
    },
    onChange(index) {
      console.log(index)
      this.$store.commit('addcount', 'ad')
    },
    todetail(item) {
      this.$router.push({ path: '/yueDetail', query: { id: item.id } });
      // this.$router.replace('/yueDetail');
      console.log(item)
    },
    getMemeberDetails() {
      this.$axios
        .request({
          url: "/api/v1/WeChat/GetMemberDetails",
          methods: "get",
          params: {
            merchantID: localStorage.getItem('MerchantID'),
            openID: localStorage.getItem('openid')
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            localStorage.setItem('user', JSON.stringify(res.data))
          } else {
            localStorage.setItem('user', '')
          }

        });
    },
    onClick(name, title) {
      console.log(name, title);
      this.getSiteInfo(name)
      // this.getSiteInfo('002')
    },
    onClick1(name, title) {
      console.log(name, title);
      this.getSportProjectInfo(name)
    },
    // onClickLeft() {
    //   Toast('返回');
    // },
    // onClickRight() {
    //   Toast('按钮');
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ddd {
  margin: 15px 0;
  padding: auto 0;
}

#ccc /deep/ .van-tabs__wrap,
#ddd /deep/ .van-tabs__wrap {
  height: 40px !important;
}

#ccc /deep/ .van-tabs__nav--card,
#ddd /deep/ .van-tabs__nav--card {
  border: 1px solid #ddd;
}

#ccc /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  color: #fff;
  background-color: #01adfb;
  ;
}

#ddd /deep/ .van-tab.van-tab--active {
  color: #01adfb;
  background-color: #f4f8fc;
}

#ccc /deep/ .van-tabs__nav--card,
#ddd /deep/ .van-tabs__nav--card {
  height: 40px;
  line-height: 40px;
  border-radius: 24px;
  overflow: auto;
}

#ddd /deep/ .van-tabs__line {
  background-color: #01adfb;
}

#ccc /deep/ .van-tabs__nav--card .van-tab,
#ddd /deep/ .van-tabs__nav--card .van-tab {
  /* background-color: #01adfb; */
  /*background-color: rgb(246, 246, 246);*/
  /* color: #fff; */
  color: #7e7e7e;
  border-right: 1px solid #fff;
}

.tit {
  text-align: left;
  padding: 0 10px;
  font-size: 14px;
  margin: 5px 0;
  text-indent: 2em;
}

.van-card__footer {
  height: 4px;
}

button.ac {
  background-color: #01adfb;
  width: 70px;
  border-radius: 35px;
  border: none;
  position: relative;
  top: -47px;
  height: 40px;
}

* {
  font-size: 14px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.van-card__title {
  font-weight: 800;
}
</style>
