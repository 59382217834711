<template>
  <div id="app" :style="{ width: width }" style="margin:0 auto;overflow: hidden;">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
<!--    <van-tabbar v-model="$store.state.statec" @change="onChange" v-if="this.$store.state.istrc" style="border-top:1px solid #ccc;">-->
<!--      <van-tabbar-item icon="home-o">在线订场</van-tabbar-item>-->
<!--      <van-tabbar-item icon="gold-coin-o">在线充值</van-tabbar-item>-->
<!--      <van-tabbar-item icon="search">个人中心</van-tabbar-item>-->
<!--    </van-tabbar>-->
  </div>
</template>

<script>
// MerchantID=f111e16be1de079cd768f4b808c47da4
import Vue from 'vue';
import { Tabbar } from 'vant';
let wxmsg = { appId: '', timeStamp: '', nonceStr: '', package: '', signType: '', paySign: '' }
// var appId='',timestamp='' ,nonceStr='', packagec='',signType='', paySign='';
// console.log(appId,timeStamp ,nonceStr, packagec,signType, paySign)
Vue.use(Tabbar);
export default {
  name: 'App',
  data: () => {
    return {
      active: 0,
      width: '100%',
      a: ''
    }
  },
  mounted() {
    let mer = this.getUrlParam('MerchantID') || this.getUrlParam('merchantID')
    console.log(mer)
    console.log(window.location)
    // localStorage.setItem('openid', "oyLwT5761otUw6q2XY28wi_sjX_8")
    if (!localStorage.getItem('MerchantID')) {
      localStorage.setItem('MerchantID', this.getUrlParam('MerchantID').replace('/',''))
    }
    console.log('我是diyi')
    this.chuliOS();
    console.log(this.$route.fullPath)
    switch (this.$route.fullPath) {
      case '/regref':
        this.active = 1;
        break;
      case '/index':
        this.active = 0;
        break;
      case '/peopleCenter':
        this.active = 2;
        break;
    }
    // this.getCode();
    window.addEventListener('resize', () => {
      this.chuliOS()
    })

  },
  components: {
    // HelloWorld
  },
  methods: {
    pay() {
      this.$wx.chooseWXPay({
        appId: wxmsg.appId,
        timestamp: wxmsg.timeStamp,
        nonceStr: wxmsg.nonceStr, // 支付签名随机串，不长于 32 位
        package: wxmsg.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
        signType: wxmsg.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: wxmsg.paySign, // 支付签名
        success: function (res) {
          // 支付成功的回调函数
          console.log(res)
        },
        cancel: function (res) {
          console.log(res)
          // 支付取消的回调函数
        },
        error: function (res) {
          console.log(res)
          // 支付失败的回调函数
        }
      })
    },
    // pay1() {
    //   let _this =this;
    //   if (typeof weixinJSBridge == "undefined") {
    //     if (document.addEventListener) {
    //       document.addEventListener('weixin3SBridgeReady', onBridgeReady, false);
    //     } else if (document.attachEvent) {
    //       document.attachEvent('weixinJSBridgeReady', onBridgeReady);
    //       document.attachEvent("onweixinJSBridgeReady", onBridgeReady);
    //     } else {
    //       weixinJSBridge.invoke(
    //         'getBrandwCPayRequest', {
    //         "appId": 'data.data.appId',//公众号商广id
    //         "timestamp": 'data.data.timestamp',//时间歌,自1970年以来的秒数
    //         "nonceStr": 'data.data.nonceStr',//随机串
    //         "package": 'data.data.package',
    //         "signType": 'data.data.signType',
    //         //微信签名方式:
    //         "paySign": 'data.data.sign' //微信签名
    //       },
    //         function (res) {
    //           if (res.err_msg == "get_brand_wcpay_request: ok") {
    //             window.location.href = "/paysuccess";
    //           } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
    //             _this.prompt('取消支付')
    //             _this.$emit('cancelPay')
    //           } else {
    //             _this.prompt('支付失败')
    //             _this.$emit('cancelPay')
    //           }
    //         })
    //     }
    //   }
    // },
    // onBridgeReady() {
    //   weixinJSBridge.invoke('getBrandwCPayRequest', {
    //     "appId": appId,//公众号名称,由商户传入
    //     "timestamp": timeStamp,//时间戳,自1976年以来的秒数"nonceStr" : nonceStr，//随机串
    //     "package": packagec,
    //     "signType": signType,//微信签名方式:"paySign" : paySign//微信签名
    //   }, function (res) {
    //     if (res.err_msg -= "get_brand_wcpay_request:ok") {
    //       console.log("支付成功");
    //       // layer.msg("支付成功);/支付成功后跳转的页面
    //       window.location.href = "/wx/ prePay/towxMainHome ";
    //     } else if (res.err_msg == "get_brand_wcpay_request: cancel") {
    //       console.log("支付取消");
    //       // layer.msg("支付取消");
    //     } else if (res.err_msg == "get_brand_wcpay_request:fail") {
    //       console.log("支付失败");
    //       // layer.msg("支付失败");
    //       weixinJSBridge.call('closelindow ');
    //     }//使用以上方式判断前端返回,微信团队郑重提示: res.err_msg将在用户支付成功后返回ok,但并不保证它绝对可靠,});
    //   })
    // },
    getUrlParam(name) {//封装方法
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象

      var r = window.location.search.substr(1).match(reg); //匹配目标参数

      if (r != null) return unescape(r[2]);

      return null; //返回参数值

    },
    // getCode() { // 非静默授权，第一次有弹框
    //   const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
    //   // const local = window.location.href

    //   if (code == null || code === '') {        
    //     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + 'wxf454517019e5fa8f' + '&redirect_uri=' + encodeURIComponent('43.138.48.29/index1.html#/') + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'

    //   } else {
    //     this.getOpenId(code) //把code传给后台获取用户信息
    //   }
    // },
    // getOpenId(code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
    //   // let _this = this
    //   this.$http.post('/api/user/wechat/login', { code: code }).then((res) => {
    //     let datas = res.data
    //     if (datas.code === 0) {
    //       console.log('成功')
    //     }
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // },
    chuliOS() {
      let os = this.os();
      if (os.isAndroid || os.isPhone) {
        console.log("手机")
        this.width = '100%'
      } else if (os.isTablet) {
        console.log("平板")
        this.width = '100%'
      } else if (os.isPc) {
        console.log("电脑")
        this.width = '500px'
      }
    },
    os() {
      var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
      isChrome;
      return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
      };
    },

    onChange(index) {
      this.title = this.$route.name;
      console.log(this.$route.name, index)
      if (index == 0) {
        this.$router.push('/index');
      } else if (index == 1) {
        this.$router.push('/regref');
      } else {
        this.$router.push('/peopleCenter');
      }
    },
    todetail(item) {
      console.log(item)
    },
    onClick(name, title) {
      console.log(title);
    },
    // onClickLeft() {
    //   Toast('返回');
    // },
    // onClickRight() {
    //   Toast('按钮');
    // },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
