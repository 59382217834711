<template>
    <div style="padding-top:46px;padding-bottom:50px;">
        <van-nav-bar title="下单" left-text="返回" left-arrow @click-left="onClickLeft" fixed />
        <van-card style='' v-for="index in 3" :key="index" num="2" tag="标签" price="2.00" desc="描述信息" title="商品标题"
            thumb="https://img01.yzcdn.cn/vant/ipad.jpeg" origin-price="10.00" />
        <van-cell-group style="margin-top: 10px;">
            <van-cell title="包装费" value="￥2" />
            <van-cell title="配送费" value="￥0" label="已减2.5元配送费" />
        </van-cell-group>
        <van-form style="margin-top: 10px;">
            <van-field v-model="peoNumber" name="peoNumber" label="用餐人数" placeholder="用餐人数"
                :rules="[{ required: true, message: '请填写用餐人数' }]" />
            <van-field v-model="remake"  name="remake" label="备注信息" placeholder="备注信息" />            
        </van-form>
        <van-goods-action style='width:80%;margin: auto;'>
            <van-goods-action-button color="#333333" type="warning" :disabled="true" text="￥31.9" />
            <van-goods-action-button color="#FECC32" @click="topay" type="danger" text="提交订单" />
        </van-goods-action>
    </div>
</template>
<script>
import Vue from 'vue';
import { NavBar, Card, Cell, CellGroup, GoodsAction, GoodsActionIcon, GoodsActionButton, Toast, Form, Field } from 'vant';
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Card);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Form);
Vue.use(Field);Vue.use(Toast);
export default {
    name: 'PayOrder',

    data() {
        return {
peoNumber:1,
remake:''
        };
    },

    mounted() {

    },

    methods: {
        topay() {
            Toast('发起支付')
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
    },
};
</script>
<style  scoped>
</style>