<template>
    <div>
        <van-form ref='form'>
            <van-cell-group>
                <van-field
                        readonly
                        clickable
                        :value="form.date"
                        maxlength="50"
                        label="游玩日期"
                        placeholder="请选择"
                        :rules="[{ required: true, message: '请选择游玩日期' }]"
                        @click="showDatePicker = true"
                />
                <van-calendar v-model="showDatePicker" @confirm="onConfirmCalendar"/>
            </van-cell-group>
            <van-cell-group>
                <van-field
                        label="游客姓名"
                        v-model = "form.name"
                        :rules="[{ required: true, message: '请输入姓名' }]"
                />
            </van-cell-group>
            <van-cell-group>
                <van-field label="手机号码" v-model='form.phone'  name="phone" :rules="telRules" ></van-field>
            </van-cell-group>
            <van-cell-group>
                <!--            -->
                <van-field label="身份证号" v-model='form.idNo'  name="idNo" :rules="idNoRules" ></van-field>
            </van-cell-group>
        </van-form>
        <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="onSubmit">
            <div style="float: right">已选：{{totalTicket}}张</div>
        </van-submit-bar>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Swipe, NavBar, SwipeItem, TabbarItem, Tab, Tabs, Card, Button, Toast, Popup, Search, PullRefresh, List, Empty } from 'vant';
    import router from "@/router";
    Vue.use(Toast);
    let cAPPID = '';
    let cAppSecret = '';
    let cmch_id = '';
    let cpay_key='';
    export default {
        name: "WriteMemberInfo",
        data: () => {
            return {
                ticketList:[],
                showDatePicker:false,
                form:{},
                action:0,
                price:0,
                totalPrice:0,
                totalTicket:0,
                orderNo:'',
                index3: 0,
                end3: 99,
                PayOptions:[],
                MerchantID: localStorage.getItem("MerchantID"),
                sportProjects:[],
                isLoading:false,
                telRules: [{
                    required: true,
                    message: '请输入手机号码',
                    trigger: 'onBlur'
                }, {
                    // 自定义校验规则
                    validator: value => {
                        return /^(0|86|17951)?(1[0-9][0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                            .test(value)
                    },
                    message: '请输入正确格式的手机号码',
                    trigger: 'onBlur'
                }],
                idNoRules:[{
                    required: true,
                    message: '请输入身份证号',
                    trigger: 'onBlur'
                }, {
                    // 自定义校验规则
                    validator: value => {
                        return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                            .test(value)
                    },
                    message: '请输入正确格式的身份证号',
                    trigger: 'onBlur'
                }],
            }
        },
        filters: {
            imgc(url) {
                // console.log(url)
                let urlc =
                    window.g.baseUrl +
                    "/api/v1/OpenApi/PullImage?PicName=" +
                    url +
                    "&PathType=2";
                // console.log(urlc);
                return urlc;
            }
        },
        props: {
            msg: String,
        },
        created() {
            this.sportProjects = router.currentRoute.params.sportProjects
            this.totalPrice = router.currentRoute.params.totalPrice
            this.totalTicket = router.currentRoute.params.totalTicket
            this.GetDataDictionaryInfo()
        },
        methods:{
            onSubmit(){
                this.$refs.form.validate().then(() => {
                    this.ticketList = []
                    let netRechargeInfoList = []
                    this.sportProjects.forEach(ele => {
                        if(ele.count == 1){
                            ele.qrcode = this.randomString(30)
                            this.ticketList.push(ele)
                        }else if(ele.count > 1){
                            for(let i = 0; i < ele.count; i++){
                                this.ticketList.push(ele)
                            }
                        }
                    })
                    // let i = 0
                    // this.ticketList.forEach(ele=>{
                    //     i+=1
                    //     let qrcode = this.randomString(30)
                    //     ele.qrcode = i
                    //     console.log(ele.qrcode)
                    //     console.log(ele)
                    // })
                    this.orderNo = this.randomString(30)
                    let netRecharge = {
                        MerchantID: localStorage.getItem("MerchantID"),
                        NetRechargeInfoList: netRechargeInfoList,
                        memberID:localStorage.getItem('openid'),
                        orderNo: this.orderNo,
                        amount: this.totalPrice/100 ,
                        createBy: localStorage.getItem('openid')}
                    this.WriteCustomerNetRechargeInfo(netRecharge)

                }).catch(() => {
                })
            },
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1)}-${date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()}`;
            },
            createWXOrder(MerchantID, OrderNo, Amount, OrderTime) {
                // Toast('第二步，去node服务器生成微信订单，去支付')
                // this.$toast.loading({
                //     message: '第二步，去node服务器生成微信订单，去支付',
                //     forbidClick: true,
                // });
                try {
                    let payParameters = localStorage.getItem('payParameters')
                    let payParametersJSON = payParameters && JSON.parse(payParameters)
                    cAPPID = payParametersJSON.weChatAppID;
                    cAppSecret = payParametersJSON.weChatKey; //支付密钥
                    cmch_id = payParametersJSON.weChatMchID;
                    cpay_key = payParametersJSON.weChatKey;

                    this.loading = true;
                    this.title = '生成微信订单'
                    let that = this;
                    // let local = 'http://43.138.48.29/token/wxresult';
                    let local = "https://zhy.jiechengan.com:8090/api/v1/OpenApi/payResult"
                    let obj = {
                        // AppSecret: '68682020122820210304BBC686820201',
                        // appid: 'wxec50473d0c0ec298',
                        // mch_id: '1608150212',
                        // key: '68682020122820210304BBC686820201',
                        appid: cAPPID,
                        mch_id: cmch_id,
                        key: cAppSecret,
                        // mch_id:'1305638280',
                        openid: localStorage.getItem('openid'),
                        timestamp: OrderTime,
                        out_trade_no: that.orderNo,
                        total_fee: Amount, //写死
                        nonceStr: this.randomString(16),
                        body: '公众号购票' + Amount + '元',
                        notify_url: local, //异步回调地址，去通知服务器的地址
                    }
                    this.$axios
                        .request({
                            url: window.g.tokenBaseUrl + "/testwxpay",
                            method: "POST",
                            data: {
                                ...obj
                            },
                        })
                        .then((res) => {
                            function onBridgeReady(res) {
                                // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                                let paySign = that.$md5(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${cpay_key}`).toUpperCase();
                                WeixinJSBridge.invoke(
                                    'getBrandWCPayRequest', {
                                        "appId": cAPPID,     //公众号ID，由商户传入
                                        "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                        "nonceStr": res.nonceStr, //随机串
                                        "package": res.package,
                                        "signType": "MD5",         //微信签名方式：
                                        "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                    },
                                    function (resc) {
                                        if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                            // 使用以上方式判断前端返回,微信团队郑重提示：
                                            // alert('支付成功')
                                            // that.paymentResult(res, resc)
                                            // that.testFindOrder(res, resc)
                                            try{
                                                that.UpdatePayState (res.out_trade_no);
                                                //that.WriteCustomerOrderInfo(0,OrderTime, that.orderNo)
                                            }
                                            catch (e) {
                                                Toast.fail({duration:2000,message:e.message})
                                            }
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                            Toast.fail({duration:1000,message:'已取消'})
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                            Toast.fail({duration:1000,message:'支付失败'})
                                        }
                                        that.value = '';
                                    });
                            }
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                onBridgeReady(res.data.data);
                            }
                            if (res.data.return_msg == 'OK') {
                                // this.wxpayFunc(res, OrderNo, 5.51 * 100);
                            } else {
                            }
                            that.loading = false;
                        });
                }
                catch (e) {
                    Toast.fail({duration:3000,message:e.message})
                }
            },
            UpdatePayState (PayOrderNo){
                this.$axios
                    .request({
                        url: "/api/v1/WXProgram/UpdateCustomerOrderInfo",
                        method: "POST",
                        data: { MerchantID: localStorage.getItem("MerchantID"), PayOrderNo: PayOrderNo, GetTicketCode: this.orderNo },
                    })
                    .then((res) => {
                        console.log(res.data.success)
                        if(res.data.success){
                            c

                        }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            WriteCustomerOrderInfo(syncIndex, payDateTime, getTicketCode){
                let payType = ''
                this.PayOptions.forEach(ele=>{
                    if(ele.dictionaryContent == '微信')
                        payType=ele.id
                })
                let orderInfoList = []
                this.ticketList.forEach(ele => {
                    let orderInfo = {
                        sportProjectID: ele.id,
                        memberID:localStorage.getItem('openid'),
                        payType: payType,
                        orderNo: this.orderNo,
                        orderDate: this.getTime(),
                        orderTime:this.getTime(),
                        amount: ele.amount,
                        orderType: 1,
                        createBy: localStorage.getItem('openid'),
                        memberType: 2,
                        humanCount:1,
                        memberName:this.form.name,
                        idno:this.form.idNo,
                        phone:this.form.phone,
                        qrCode:ele.qrcode,
                        cardNO:'',
                        payTime:this.getTime(),
                        effectiveBegin: '2023-04-01',
                        effectiveEnd: '2055-05-05',
                        isPay: 0
                    }
                    orderInfoList.push(orderInfo)
                })
                let req_order = {syncIndex: syncIndex, payDateTime: payDateTime,payTime: payDateTime, payOrderNo: getTicketCode, payState: syncIndex == 1 ? 0:1, payType:payType, merchantID:localStorage.getItem('MerchantID'),getTicketCode:this.orderNo,orderInfoList:orderInfoList}

                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/WriteCustomerOrderInfo",
                        method: "POST",
                        data: { ...req_order },
                    })
                    .then((res) => {
                        console.log(res.data.success)
                        // if(res.data.success){
                        //     Toast.success({duration:1000,message:'购票成功',onClose:function () {
                        //             router.push({name:'orderDetail'})
                        //         }})
                        //
                        // }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            WriteCustomerNetRechargeInfo(netRecharge){
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/WriteCustomerNetRechargeInfo",
                        method: "POST",
                        data: { ...netRecharge },
                    })
                    .then((res) => {
                        console.log(res)
                        if(res.data.success){
                            this.WriteCustomerOrderInfo(0,this.dateFormat(), this.orderNo)
                            this.createWXOrder(this.MerchantID, netRecharge.orderNo,this.totalPrice,this.dateFormat())
                            //this.createWXOrder(this.MerchantID, netRecharge.orderNo,this.totalPrice,this.dateFormat())

                        }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            randomString(e) {
                e = e || 32;
                let t = "0123456789",
                    a = t.length,
                    n = "";
                for (let i = 0; i < e; i++) {
                    n += t.charAt(Math.round(Math.random() * a));
                }
                return n
            },
            guid() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }
                return S4() + S4() + S4() + S4() + S4() + S4();
            },
            dateFormat() {
                var datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month =
                    datec.getMonth() + 1 < 10
                        ? "0" + (datec.getMonth() + 1)
                        : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                var hours =
                    datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
                var minutes =
                    datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
                var seconds =
                    datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
                // 拼接
                return ''+year + month + day + hours + minutes + seconds;
            },
            GetDataDictionaryInfo() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                        methods: "get",
                        params: {
                            node: 999,
                            MerchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let data = res.data.models;
                            data.forEach((item) => {if (item.parentNode == 10) {
                                this.PayOptions.push(item);
                            }
                            });
                        }
                    });
            },
            getTime() {
                Date.prototype.Format = function(fmt) {
                    var o = {
                        "M+": this.getMonth() + 1, //月份
                        "d+": this.getDate(), //日
                        "H+": this.getHours(), //小时
                        "m+": this.getMinutes(), //分
                        "s+": this.getSeconds(), //秒
                        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
                        S: this.getMilliseconds() //毫秒
                    };
                    if (/(y+)/.test(fmt))
                        fmt = fmt.replace(
                            RegExp.$1,
                            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                        );
                    for (var k in o)
                        if (new RegExp("(" + k + ")").test(fmt))
                            fmt = fmt.replace(
                                RegExp.$1,
                                RegExp.$1.length == 1
                                    ? o[k]
                                    : ("00" + o[k]).substr(("" + o[k]).length)
                            );
                    return fmt;
                };

                var a = new Date().Format("yyyy-MM-dd HH:mm:ss");

                return (this.nowtime = a);
            },
            onConfirmCalendar(date){
                this.showDatePicker = false;
                this.form.date = this.formatDate(date);
            },
        }
    }
</script>

<style scoped>

</style>