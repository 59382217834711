<template>
    <div style="width: 90%;margin-left: 5%">
        <div class="title">{{mealName}}</div>
        <el-divider></el-divider>
        <div class="title">购票须知</div>
        <span class="detail">{{reserve5}}</span>
        <el-divider></el-divider>
        <div class="title">使用说明</div>
        <span  class="detail">{{reserve6}}</span>
        <el-divider></el-divider>
        <div class="title">其他说明</div>
        <span  class="detail">{{reserve7}}</span>
        <van-submit-bar :price="price * 100" button-text="提交订单" @submit="onSubmit">
        </van-submit-bar>
    </div>
</template>

<script>
    import router from "@/router";
    import {Toast} from "vant";
    import CryptoJS from "crypto-js";

    const decrypt = (ciphertext, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrpted = CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return decrpted.toString(CryptoJS.enc.Utf8)
    }
    import Vue from 'vue';
    Vue.use(Toast);
    let cAPPID = '';
    let cpay_key='';
    let cAppSecret = '';
    let cmch_id = '';
    export default {
        name: "VIPDesc",
        data: () => {
            return {
                mealName:'',
                reserve5:'',
                reserve6:'',
                reserve7:'',
                mealTypeID: '',
                price: 0,
                memberID: '',
                effectiveDays:0,
                limitTimes:0,
                cid:'',
                PayOptions:[],
            }
        },
        created() {
            console.log(router.currentRoute.params)
            this.mealName = router.currentRoute.params.mealName
            this.reserve5 = router.currentRoute.params.reserve5
            this.reserve6 = router.currentRoute.params.reserve6
            this.reserve7 = router.currentRoute.params.reserve7
            this.mealTypeID = router.currentRoute.params.mealTypeID
            this.price = router.currentRoute.params.price
            this.effectiveDays = router.currentRoute.params.effectiveDays
            this.limitTimes = router.currentRoute.params.limitTimes
            this.GetPaymentParameters()
            this.GetDataDictionaryInfo()
        },
        methods:{
            GetDataDictionaryInfo() {
                this.$axios
                    .request({
                        url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                        methods: "get",
                        params: {
                            node: 999,
                            MerchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            let data = res.data.models;
                            data.forEach((item) => {if (item.parentNode == 10) {
                                this.PayOptions.push(item);
                            }
                            });
                        }
                    });
            },
            onSubmit(){
                let openid = localStorage.getItem('openid')
                this.MemberID = this.guid()
                let Member = {id: openid,memberNo: openid, memberName: openid, reservedFieldsFri: openid,mealTypeID: this.mealTypeID}
                this.$axios
                    .request({
                        method: "POST",
                        url: "/api/v1/WeChat/WriteMemberInfo",
                        data: {
                            ...Member,MerchantID: localStorage.getItem('MerchantID'),czBalance: 0
                        },
                    }).then((res) => {
                        if(res.data.success){
                            this.WriteRechargeInfo()
                        }
                })
            },
            GetPaymentParameters() {
                this.$axios
                    .request({
                        method: "GET",
                        url: "/api/v1/OpenApi/GetPaymentParameters",
                        params: {
                            merchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        console.log(res.data);
                        let obj = {
                            alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
                            alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
                            alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
                            merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
                            merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
                            weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key),
                            weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key),
                            weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
                            weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
                            weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
                            weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
                        }
                        // console.log({...res.data,...obj});
                        // this.$EventBus.$emit("asd", {
                        //   ...res,
                        //   title: "支付参数",
                        //   ctx: "获取支付参数信息" + "",
                        // });
                        localStorage.setItem('payParameters', JSON.stringify(obj))
                        // cAPPID = obj.weChatAppID;
                        // cAppSecret = obj.weChatKey; //支付密钥
                        // cmch_id = obj.weChatMchID;
                        // this.form = res.data;
                        // this.getCode();
                    });
            },
            WriteCustomerNetRechargeInfo(){

                this.$axios
                    .request({
                        method: "POST",
                        url: "/api/v1/OpenApi/WriteNetRechargeInfo",
                        data: {
                            id: this.cid,
                            merchantID: localStorage.getItem("MerchantID"),
                            memberID: localStorage.getItem('openid'),
                            orderNo: this.cid,
                            amount: this.price,
                            createBy: localStorage.getItem("id"),
                            remarks: "",
                        },
                    })
                    .then((res) => {
                        if(res.data.success){
                            this.createWXOrder(this.MerchantID, this.cid,this.price*100,this.dateFormat())
                            //this.UpdateRechargePayState()
                            //this.createWXOrder(this.MerchantID, netRecharge.orderNo,this.totalPrice,this.dateFormat())

                        }
                    }).catch((err) => {
                    Toast(err)
                })
            },
            WriteRechargeInfo(){
                this.cid = this.guid();
                this.$axios
                    .request({
                        method: "POST",
                        url: "/api/v1/OpenApi/WriteRechargeInfo",
                        data: {
                            id: this.cid,
                            rechargeTime: this.dateFormat(),
                            mealTypeID: this.mealTypeID,
                            reservedFieldsSec: this.limitTimes,
                            receiptsAmount: this.price,
                            merchantID: localStorage.getItem("MerchantID"),
                            memberID: localStorage.getItem('openid'),
                            orderNo: this.cid,
                            amount: this.price,
                            createBy: localStorage.getItem("id"),
                            remarks: ""
                        },
                    }).then((res) => {
                        if(res.data.success) {
                            this.WriteCustomerNetRechargeInfo()
                        }
                })
            },
            createWXOrder(MerchantID, OrderNo, Amount, OrderTime) {
                // Toast('第二步，去node服务器生成微信订单，去支付')
                // this.$toast.loading({
                //     message: '第二步，去node服务器生成微信订单，去支付',
                //     forbidClick: true,
                // });
                try {
                    if(localStorage.getItem('payParameters') == null)
                    {
                        this.GetPaymentParameters()
                    }
                    let payParameters = localStorage.getItem('payParameters')
                    let payParametersJSON = payParameters && JSON.parse(payParameters)
                    cAPPID = payParametersJSON.weChatAppID;
                    cAppSecret = payParametersJSON.weChatKey; //支付密钥
                    cmch_id = payParametersJSON.weChatMchID;
                    cpay_key = payParametersJSON.weChatKey;

                    this.loading = true;
                    this.title = '生成微信订单'
                    let that = this;
                    // let local = 'http://43.138.48.29/token/wxresult';
                    let local = "https://zhy.jiechengan.com:8090/api/v1/OpenApi/payResult"
                    let obj = {
                        // AppSecret: '68682020122820210304BBC686820201',
                        // appid: 'wxec50473d0c0ec298',
                        // mch_id: '1608150212',
                        // key: '68682020122820210304BBC686820201',
                        appid: cAPPID,
                        mch_id: cmch_id,
                        key: cAppSecret,
                        // mch_id:'1305638280',
                        openid: localStorage.getItem('openid'),
                        timestamp: OrderTime,
                        out_trade_no: that.cid,
                        total_fee: Amount, //写死
                        nonceStr: this.randomString(16),
                        body: '公众号购会员卡' + Amount + '元',
                        notify_url: local, //异步回调地址，去通知服务器的地址
                    }
                    this.$axios
                        .request({
                            url: window.g.tokenBaseUrl + "/testwxpay",
                            method: "POST",
                            data: {
                                ...obj
                            },
                        })
                        .then((res) => {
                            function onBridgeReady(res) {
                                // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                                let paySign = that.$md5(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=${cpay_key}`).toUpperCase();
                                WeixinJSBridge.invoke(
                                    'getBrandWCPayRequest', {
                                        "appId": cAPPID,     //公众号ID，由商户传入
                                        "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数
                                        "nonceStr": res.nonceStr, //随机串
                                        "package": res.package,
                                        "signType": "MD5",         //微信签名方式：
                                        "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                    },
                                    function (resc) {
                                        if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                            // 使用以上方式判断前端返回,微信团队郑重提示：
                                            // alert('支付成功')
                                            // that.paymentResult(res, resc)
                                            // that.testFindOrder(res, resc)
                                            try{
                                                that.UpdateRechargePayState()
                                                //that.UpdatePayState (res.out_trade_no);
                                                //that.WriteCustomerOrderInfo(0,OrderTime, that.orderNo)
                                            }
                                            catch (e) {
                                                Toast.fail({duration:2000,message:e.message})
                                            }
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                            Toast.fail({duration:1000,message:'已取消'})
                                        }
                                        if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                            Toast.fail({duration:1000,message:'支付失败'})
                                        }
                                        that.value = '';
                                    });
                            }
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                onBridgeReady(res.data.data);
                            }
                            if (res.data.return_msg == 'OK') {
                                // this.wxpayFunc(res, OrderNo, 5.51 * 100);
                            } else {
                            }
                            that.loading = false;
                        });
                }
                catch (e) {
                    Toast.fail({duration:3000,message:e.message})
                }
            },
            UpdateRechargePayState() {
                let payType = ''
                this.PayOptions.forEach(ele=>{
                    if(ele.dictionaryContent == '微信')
                        payType=ele.id
                })
                let period = new Date()
                period.setDate(period.getDate() + this.effectiveDays);
                console.log(period.getFullYear())
                let req = {
                        id: this.cid, //充值的ID
                        merchantID: localStorage.getItem('MerchantID'),
                        payType: payType,    //支付方式 0 微信 1 支付宝 id
                        payState: 1,
                        isPay: 1,
                        payTime: this.dateFormat(),
                        MemberType: 0,
                        // payOrderNo:  a.payTradeNo || this.orderNo,
                        payOrderNo: this.cid,
                        reservedFieldsSec: this.limitTimes,
                        period: this.dateFormat1(period),
                        isEditMember : 1
                    }

                // console.log(this.datac.WriteRechargeInfoData.id,this.datac.idc,this.datac.WriteRechargeInfoData.id || this.datac.idc)
                // return false;

                this.$axios
                    .request({
                        method: "POST",
                        url: "/api/v1/OpenApi/UpdateRechargePayState",
                        data: req,
                    })
                    .then((res) => {
                        if(res.data.success) {
                            this.UpdateNetRechargePayState()
                        }
                        else {
                            Toast.fail({duration:1000,message:res.data.message})
                        }
                    });
            },
            // 更新支付订单状态信息
            UpdateNetRechargePayState() {
                let payType = ''
                this.PayOptions.forEach(ele=>{
                    if(ele.dictionaryContent == '微信')
                        payType=ele.id
                })
                // return false;
                this.$axios
                    .request({
                        method: "POST",
                        url: "/api/v1/OpenApi/UpdateNetRechargePayState",
                        data: {
                            id: this.cid, //充值的ID
                            merchantID: localStorage.getItem('MerchantID'),
                            payType: payType,   //支付方式 0 微信 1 支付宝 id
                            payState: 1,
                            payTime: this.dateFormat(),
                            isPay: 1,
                            MemberType: 0,
                            // payOrderNo: a.payTradeNo || this.orderNo,
                            payOrderNo: this.cid,
                        },
                    })
                    .then((res) => {
                        if(res.data.success){
                            Toast.success({duration:3000,message:'购票成功,点击"我的会员"查看会员码',onClose:function () {
                                    router.push({name:'个人中心'})
                                }})
                        }
                        else {
                            Toast.fail({duration:1000,message:res.data.message})
                        }
                    });
            },
            randomString(e) {
                e = e || 32;
                let t = "0123456789",
                    a = t.length,
                    n = "";
                for (let i = 0; i < e; i++) {
                    n += t.charAt(Math.round(Math.random() * a));
                }
                return n
            },
            guid() {
                function S4() {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }
                return S4() + S4() + S4() + S4() + S4() + S4() + S4();
            },
            dateFormat1(datec) {
                if(datec == null)
                    datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month =
                    datec.getMonth() + 1 < 10
                        ? "0" + (datec.getMonth() + 1)
                        : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                // 拼接
                return ''+year + '/' + month + '/' + day;
            },
            dateFormat(datec) {
                if(datec == null)
                    datec = new Date();
                var year = datec.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                var month =
                    datec.getMonth() + 1 < 10
                        ? "0" + (datec.getMonth() + 1)
                        : datec.getMonth() + 1;
                var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
                var hours =
                    datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
                var minutes =
                    datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
                var seconds =
                    datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
                // 拼接
                return ''+year + month + day + hours + minutes + seconds;
            },
        }
    }
</script>

<style scoped>

</style>