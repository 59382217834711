<template>
    <div style="padding-top:46px;" v-loading.fullscreen.lock="loading" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <van-nav-bar title="购票查询" fixed @click-right="selOrder">
            <template #right>
                <van-icon name="search" size="18" />
            </template>
        </van-nav-bar>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
            <van-tabs v-model="active" id="eee" title-active-color="#c47c5f" color="#c47c5f" swipeable
                @change="titlechange">
                <van-tab title="全部" style="margin-top:10px;">
                    <div>
                        <van-card v-for='item in areas' :key='item.id' num="1" :centered='!!abc'
                            :tag="item.isPay == 0 ? '未支付' : item.isPay == 1 ? (item.orderState == 0 ? '未核销' : item.orderState == 1 ? '已核销' : '取消') : '已退款'"
                            :price="item.amount"
                            :title="item.sportProjectName + ' - ' + (item.isPay == 0 ? '未支付' : item.isPay == 1 ? '已支付' : '已退款')"
                            :thumb="item.sportProjectPic1">
                            <template #tags>
                                <div style='color:red;margin:5px 0;'>预约日期:{{ item.orderDate.split(' ')[0] }}</div>
<!--                                <div style='color:red;margin:5px 0;'>预约时间:{{ item.startTime }}-{{ item.endTime }}</div>-->
                                <div style='margin:5px 0;'>订单号:{{ item.payOrderNo || item.orderNo }}</div>
                                <div style='margin:5px 0;'>订单类型:{{ item.orderType == 0 ? '线下订单' : ' 线上订单' }}</div>
                            </template>
                            <template #footer>
                                <van-button size="mini" style="color:#fff;"
                                    v-if='item.isPay == 1 && item.orderState == 0' @click='orderRefund(item)'>退票
                                </van-button>
                                <van-button size="mini" style="color:#fff;"
                                    v-if='item.isPay == 1 && item.orderState == 0' @click='createQRcode(item)'>取票码
                                </van-button>
                                <van-button size="mini" style="color:#fff;"
                                            v-if='item.isPay == 1 && item.orderState == 0' @click='createQRcode_Verify(item)'>入园码
                                </van-button>
                            </template>
                        </van-card>
                        <van-empty v-if='areas.length == 0' description="无订单信息" />
                    </div>
                </van-tab>
                <van-tab title="待使用" style="margin-top:10px;">
                    <div>
                        <div>
                            <van-card v-for='item in areas1' :key='item.id' num="1" :centered='!!abc'
                                :tag="item.isPay == 0 ? '未支付' : item.isPay == 1 ? (item.orderState == 0 ? '未核销' : item.orderState == 1 ? '已核销' : '取消') : '已退款'"
                                :price="item.amount"
                                :title="item.sportProjectNo + ' - ' + (item.isPay == 0 ? '未支付' : item.isPay == 1 ? '已支付' : '已退款')"
                                :thumb="item.sportProjectPic1">
                                <template #tags>
                                    <div style='color:red;margin:5px 0;'>预约日期:{{ item.orderDate }}</div>
<!--                                    <div style='color:red;margin:5px 0;'>预约时间:{{ item.startTime }}-{{ item.endTime }}-->

                                    <div style='margin:5px 0;'>订单号:{{ item.payOrderNo || item.orderNo }}</div>
                                    <div style='margin:5px 0;'>订单类型:{{ item.orderType == 0 ? '线下订单' : ' 线上订单' }}</div>
                                </template>
                                <template #footer>
                                    <van-button size="mini" style="color:#fff;"
                                        v-if='item.isPay == 1 && item.orderState == 0' @click='orderRefund(item)'>退票
                                    </van-button>
                                    <van-button size="mini" style="color:#fff;"
                                        v-if='item.isPay == 1 && item.orderState == 0' @click='createQRcode(item)'>取票码
                                    </van-button>
                                    <van-button size="mini" style="color:#fff;"
                                                v-if='item.isPay == 1 && item.orderState == 0' @click='createQRcode_Verify(item)'>入园码
                                    </van-button>
                                </template>
                            </van-card>
                            <van-empty v-if='areas1.length == 0' description="无订单信息" />
                        </div>
                    </div>
                </van-tab>
                <van-tab title="已使用" style="margin-top:10px;">
                    <div>
                        <div>
                            <van-card v-for='item in areas2' :key='item.id' num="1" :centered='!!abc'
                                :tag="item.orderState == 0 ? '未核销' : item.orderState == 1 ? '已核销' : '取消'"
                                :price="item.amount"
                                :title="item.sportProjectNo + ' - ' + (item.isPay == 0 ? '未支付' : item.isPay == 1 ? '已支付' : '已退款')"
                                :thumb="item.sportProjectPic1">
                                <template #tags>
                                    <div style='color:red;margin:5px 0;'>预约日期:{{ item.orderDate }}</div>
<!--                                    <div style='color:red;margin:5px 0;'>预约时间:{{ item.startTime }}-{{ item.endTime }}-->

                                    <div style='margin:5px 0;'>订单号:{{ item.payOrderNo || item.orderNo }}</div>
                                    <div style='margin:5px 0;'>订单类型:{{ item.orderType == 0 ? '线下订单' : ' 线上订单' }}</div>
                                </template>
                            </van-card>
                            <van-empty v-if='areas2.length == 0' description="无订单信息" />
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </van-pull-refresh>
        <!-- 搜索面板 -->
        <van-popup v-model="show" position="bottom" closeable close-icon="close">
            <div>
                <div style='margin-top:20px;text-align:center;font-size:16px;margin-bottom:10px;'>查询条件</div>

                <van-cell title="订单日期区间" :value="date" @click="show2 = true" />
                <van-calendar v-model="show2" type="range" @confirm="onConfirm" :min-date="minDate"
                    :max-date="maxDate" />
                <van-form @submit="onSubmit">
                    <van-field name="radio" label="订单状态" placeholder="订单状态">
                        <template #input>
                            <van-radio-group v-model="obj.OrderState" direction="horizontal">
                                <van-radio name="0" icon-size="12px">未核销</van-radio>
                                <van-radio name="1" icon-size="12px">已核销</van-radio>
                                <van-radio name="2" icon-size="12px">已取消</van-radio>
                                <van-radio name="99" icon-size="12px">全部</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field name="radio" label="支付状态" placeholder="支付状态">
                        <template #input>
                            <van-radio-group v-model="obj.isPay" direction="horizontal">
                                <van-radio name="0" icon-size="12px">未支付</van-radio>
                                <van-radio name="1" icon-size="12px">已支付</van-radio>
                                <van-radio name="2" icon-size="12px">已退款</van-radio>
                                <van-radio name="99" icon-size="12px">全部</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <!-- <van-field name="订单日期" label="订单日期" placeholder="订单日期">
                        <template #input>
                            <van-cell :value="obj.OrderDate" @click="show3 = true" />
                            <van-calendar v-model="show3" @confirm="onConfirm1" :min-date="minDate" :max-date="maxDate"/>
                        </template>
                    </van-field> -->
                    <van-field v-model="obj.OrderNo" name="订单号" label="订单号" placeholder="订单号" />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
        <van-dialog v-model="show4" :title="titilecc">
            <div style="max-height:350px;overflow-y: auto;">
                <div style='display:flex;background-color: #fafafa;;justify-content: center;align-items: center;padding:10px ;'
                    v-for='item in cpeopleArr' :key='item.id'>
                    <div>
                        <img :src="item.entouragePic | imgc1" alt="" style="width:80px;height:auto;border-radius:5px;">
                    </div>
                    <div style='flex:1;margin-left: 10px;'>
                        <p style="color: #323233;font-size: 12px;">姓名:{{ item.entourageName }}</p>
                        <p style="color: #323233;font-size: 12px;">证件类型:{{ item.dictionaryContent }}</p>
                        <p style="color: #323233;font-size: 12px;">证件号码:{{ item.idno }}</p>
                    </div>
                    <van-button size="mini" style="color:#fff;margin-top:50px;"
                        @click='createQRcode(item)'>取票码
                    </van-button>
                    <van-button size="mini" style="color:#fff;"
                                v-if='item.isPay == 1 && item.orderState == 0' @click='createQRcode_Verify(item)'>入园码
                    </van-button>
                </div>
            </div>

        </van-dialog>
        <van-dialog v-model="show5" :title="qrCodeTitle">
            <div id="rfd" style='display: flex;justify-content: center;padding:10px 0;width:60%;    margin-left: 20%;'>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import QRCode from 'qrcodejs2'
import { Tab, Tabs, Button, NavBar, Card, PullRefresh, Toast, Calendar, Popup, Form, Field, Cell, CellGroup, RadioGroup, Radio, Empty, Dialog } from 'vant';
Vue.use(Tab); Vue.use(Form);
Vue.use(Field); Vue.use(Cell);
Vue.use(CellGroup); Vue.use(Dialog);
Vue.use(Radio); Vue.use(Empty);
Vue.use(RadioGroup);
Vue.use(Tabs); Vue.use(Popup);
Vue.use(Button); Vue.use(Toast);
Vue.use(NavBar); Vue.use(Calendar);
Vue.use(Card); Vue.use(PullRefresh);
export default {
    name: 'OrderDetail',

    data() {
        return {
            active: 0,
            abc: true,
            qrCodeTitle:'',
            count: 0,
            isLoading: false,
            loading: false,
            minDate: new Date(2022, 6, 1), maxDate: new Date(2025, 12, 31),
            show: false,
            show2: false,
            show3: false, cpeopleArr: [],
            show4: false,
            show5: false,
            index1: 0,
            end1: 99,
            userName: '',
            titilecc: '',
            curTitle: 0,
            obj: {  //查询条件
                OrderState: '99',
                OrderDate: '',
                OrderNo: '',
                isPay: '99'
            },
            balance: '',
            memberId: '',
            areas: [],
            areas1: [],
            areas2: [],
            findArr: [],
            date: '',
            a: '', b: '', a1: '', b1: '', tc: ''
        };
    },

    mounted() {
        // 默认查询7天之前的日期
        let a = this.dateFormat1(new Date());
        let b = this.dateFormat1(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
        this.a = a;
        this.b = b;
        this.getorderInfo();
        // console.log(a, b, this.a, this.b)
        // let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        // if (user && user.id) {
        //     let { memberName, phone, balance, id } = user;
        //     this.userName = memberName + ' / ' + phone;
        //     this.balance = balance;
        //     this.memberId = id;
        //     this.getorderInfo();
        // } else {
        //     this.getMemeberDetails(1);
        // }


    },
    filters: {
        imgc(url) {
            console.log(url)
            if (!url) {
                return ''
            }
            let urlc =
                window.g.baseUrl +
                "/api/v1/OpenApi/PullImage?PicName=" +
                url +
                "&PathType=2";
            // console.log(urlc);
            return urlc;
        },
        imgc1(url) {
            console.log(url)
            if (!url) {
                return ''
            }
            let urlc =
                window.g.baseUrl +
                "/api/v1/OpenApi/PullImage?PicName=" +
                url +
                "&PathType=1";
            // console.log(urlc);
            return urlc;
        }
    },
    methods: {
        createQRcode(item) {
            this.qrCodeTitle = '取票码'
            this.show5 = true;
            this.$nextTick(() => {
                var tc = document.getElementById('rfd');
                tc.innerHTML = '';
                var qrcode = new QRCode('rfd', {
                    text: item.orderNo,
                    width: 256,
                    height: 256,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            })
        },
        createQRcode_Verify(item) {
            this.qrCodeTitle = '核销码'
            this.show5 = true;
            this.$nextTick(() => {
                var tc = document.getElementById('rfd');
                tc.innerHTML = '';
                var qrcode = new QRCode('rfd', {
                    text: item.qrCode,
                    width: 168,
                    height: 168,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            })
        },
        orderRefund(item) {
            console.log('退单', item)
            Dialog.confirm({
                title: '提示',
                message: '此操作将退票校验订单, 是否继续?',
            })
                .then((res) => {
                    console.log(res)
                    this.refundVerificationOrderAjax(item)
                })
                .catch((err) => {
                    console.log(err)
                    // on cancel
                });
        },
        refundVerificationOrderAjax(item) {
            console.log(item)
            // return false;
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/RefundVerificationOrder",
                    data: {
                        orderNo: item.orderNo,
                        merchantID: localStorage.getItem('MerchantID'),
                    },
                })
                .then((res) => {
                    console.log(res)
                    if (res.data.success) {
                        if (res.data.message == '线上退款') {
                            this.CodeScanningRefund(item);
                        } else if (res.data.message == '线下退款') {
                            this.UpdateOrderRefundState(item, '线下退款')
                        }
                        // this.$message({
                        //     message: res.data.message,
                        //     type: "success",
                        // });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                    // this.CodeScanningRefund(row, index);
                })
        },
        CodeScanningRefund(row) {
            console.log(row)
            // return false;
            this.$axios
                .request({
                    method: "POST",
                    url: '/api/v1/OpenApi/CodeScanningRefund',
                    data: {
                        id: this.guid(),
                        merchantID: localStorage.getItem('MerchantID'),
                        //会员ID
                        MemberID: row.memberID,
                        //支付订单号--可为空
                        PayOrderNo: row.payOrderNo,
                        //退款金额
                        RefundAmount: row.amount,
                        //总金额--可为空
                        TotalAmount: row.amount,
                        //支付渠道---0 官方 1 预留
                        PaymentChannels: 0,
                        //支付方式---0 微信 1 支付宝
                        PaymentType: row.payTypeName == '微信' ? 0 : 1
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.message == '退款成功') {
                        this.UpdateOrderRefundState(row)
                        this.$message({
                            type: "success",
                            message: res.data.message || "操作失败",
                        });
                    } else {
                        this.UpdateOrderRefundState(row)
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }

                });
        },
        UpdateOrderRefundState(row, a) {
            console.log(row);
            this.$axios
                .request({
                    method: "POST",
                    url: '/api/v1/OpenApi/UpdateOrderRefundState',
                    data: {
                        orderNo: row.orderNo,
                        adminID: '',
                        memberType: row.memberType,
                        refundAmount: row.amount,
                        refundTime: this.dateFormat(),
                        refundOrderNo: row.orderNo,
                        // id: this.guid(),
                        merchantID: localStorage.getItem('MerchantID'),
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        if (a) {
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                        }

                        this.getorderInfo();
                    }
                    console.log(res);


                });
        },

        GetEntourageInfo(item) {
            console.log('查看随行人员', item)
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetEntourageInfo",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        // openID: localStorage.getItem('openid'),
                        OrderID: item.id
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        this.show4 = true;
                        this.cpeopleArr = res.data.models;
                        this.titilecc = '随行人员(' + this.cpeopleArr.length + ')';
                        console.log(this.show4)
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });

                    }


                });
        },
        guid() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return S4() + S4() + S4() + S4() + S4() + S4() + S4();
        },
        onSubmit() {
            console.log('成功过', this.obj, this.a, this.b)
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            this.findArr[this.curTitle] = Object.assign({}, this.obj);
            this.getorderInfo(this.obj.OrderNo, bb, aa, this.obj.OrderState, this.obj.isPay);
            this.show = false;
        },
        formatDate(date) {
            return ''+`${date.getFullYear()}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}/${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;
        },
        onConfirm(date) {
            console.log(date[0], date[1])
            const [start, end] = date;
            this.show2 = false;
            this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
            this.b1 = `${this.formatDate(start)}`.replace(/\//g, '');
            this.a1 = `${this.formatDate(end)}`.replace(/\//g, '');
        },
        onConfirm1(date) {
            console.log(date)
            this.show3 = false;
            this.obj.OrderDate = `${this.formatDate(date)}`;
        },
        getMemeberDetails(isc) {
            console.log(isc)

            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, balance, id } = res.data;
                        this.userName = memberName + ' / ' + phone;
                        this.balance = balance
                        this.memberId = id;
                        if (isc == 1) {
                            console.log(isc)
                            this.getorderInfo(OrderNo, this.b, this.a, 99);
                        }

                    } else {
                        localStorage.setItem('user', '')
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    }

                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        selOrder() {
            console.log('搜索订单')
            //  this.findArr[this.curTitle]=this.obj;
            console.log(this.findArr, this.findArr[this.curTitle])
            this.obj = this.findArr[this.curTitle] || this.clear(this.obj);
            console.log(this.obj)
            this.show = true;
        },
        clear(obj) {
            for (const iterator in obj) {
                console.log(obj, iterator)
                obj[iterator] = ''
                if (this.curTitle == 0) {
                    obj.OrderState = '99';
                    obj.isPay = '99'
                }
                if (this.curTitle == 1) {
                    obj.OrderState = '0';
                    obj.isPay = '1'
                }
                if (this.curTitle == 2) {
                    obj.OrderState = '1';
                    obj.isPay = '1'
                }
            }
            console.log(obj)
            return obj;
        },
        onRefresh() {
            setTimeout(() => {
                Toast('刷新成功');
                this.isLoading = false;
                this.count++;
            }, 1000);
        },
        getorderInfo(OrderNo = '', StartDate = this.b, EndDate = this.a, OrderState = 99, isPay = 99) {
            console.log(OrderState)
            this.loading = true
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetOrderInfo?Orderby",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        StartIndex: this.index1,
                        MemberID: localStorage.getItem('openid'),
                        EndIndex: this.end1,
                        OrderNo: OrderNo,
                        OrderState: OrderState,  // 0 未核销 1 已核销 2 取消  99 全部
                        StartDate: StartDate,
                        EndDate: EndDate,
                        isPay: isPay,
                        MemberType: 0
                    },  //MerchantID,MemberID,OrderNo,MemberType,StartDate,&EndDate,&Orderby,&StartIndex,&EndIndex,
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        //赋值     
                        console.log(res.data)
                        // this.areas = res.data.models;
                        if (res.data.models.length > 0) {
                            console.log(this.curTitle)
                            if (this.curTitle == 0) {
                                this.areas = res.data.models;
                            }

                            // let a = [], b = [];
                            // res.data.models.length > 0 && res.data.models.forEach(ele => {
                            //     // console.log(ele)
                            //     if (ele.orderState == 0) {
                            //         a.push(ele)
                            //     }
                            //     if (ele.orderState == 1) {
                            //         b.push(ele)
                            //     }
                            // })
                            if (this.curTitle == 1) {
                                this.areas1 = res.data.models;
                            }
                            if (this.curTitle == 2) {
                                this.areas2 = res.data.models;
                            }


                        }
                    } else {
                        this.areas = [];
                        this.areas1 = [];
                        this.areas2 = [];
                        // Toast(res.data.message)
                    }
                    this.loading = false
                }).catch((err) => {
                    Toast(err)
                })
        },
        titlechange(name, title) {
            console.log(name, title)
            let OrderState = name == 0 ? 99 : name == 1 ? 0 : 1;
            let isPay = name == 0 ? 99 : name == 1 ? 1 : 1;
            let bb = this.b1 ? this.b1 : this.b;
            let aa = this.a1 ? this.a1 : this.a;
            console.log(OrderState, this.a, this.b)
            this.curTitle = name;
            this.getorderInfo('', bb, aa, OrderState, isPay); //未核销，已经支付
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
        toding(index) {
            console.log(index)
            this.a1 = '';
            this.b1 = '';
            this.obj.OrderState = '';
            this.obj.OrderNo = '';
            this.$router.push('/dingcan')
        },
        dateFormat1(dateq) {
            var datec = new Date(dateq);
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            // var hours =
            //   date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            // var minutes =
            //   date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            // var seconds =
            //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            // 拼接
            return ''+year + month + day
        },
        dateFormat() {
            var datec = new Date();
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            var hours =
                datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
            var minutes =
                datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
            var seconds =
                datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
            // 拼接
            return ''+year + month + day + hours + minutes + seconds;
        },
    },
};
</script>
<style  scoped>
.empty {
    display: inline-block;
    text-align: center;
    margin-top: 20%;
    width: 100%;
    color: #999;
}

button {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    background-color: #f26a40;
    border-color: #f26a40;
}

.van-card__thumb {
    top: 14px;
}
</style>