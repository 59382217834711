<template>
    <div>
        <div style="margin:20px 15px;padding-bottom:50px;">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
                <!-- <van-list v-model="loading" :finished="finished" finished-text="暂时没有可预约的项目" @load="onLoad"> -->
                    <van-card  style="background-color: #fff;"
                               v-for="item in sportProjects" :key='item.id' :title="item.mealName"
                               :thumb="item.reserve8" @click="toTicketDesc(item)">
                        <template #price>
                            <div style="font-size: 17px;color: red">￥{{item.reserve4}}</div>
                        </template>
                        <template #tags>
                            <div style="margin: 5px 0 ;color: #777;">{{item.mealName}}</div>
                        </template>
                    </van-card>
                <!-- </van-list> -->
                <van-empty v-if='sportProjects.length == 0' description="暂时没有可预约的项目" />
            </van-pull-refresh>
        </div>

    </div>
</template>

<script>
    import CryptoJS from "crypto-js";

    const decrypt = (ciphertext, key) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrpted = CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return decrpted.toString(CryptoJS.enc.Utf8)
    }
    import Vue from 'vue';
    import { Swipe, NavBar, SwipeItem, TabbarItem, Tab, Tabs, Card, Button, Toast, Popup, Search, PullRefresh, List, Empty } from 'vant';
    import router from "@/router";
    Vue.use(Toast);
    let cAPPID = '';
    let cpay_key='';
    let cAppSecret = '';
    let cmch_id = '';

    export default {
        name: "VIPBuy",
        data: () => {
            return {

                ticketList:[],
                showDatePicker:false,
                form:{},
                action:0,
                price:0,
                totalPrice:0,
                totalTicket:0,
                orderNo:'',
                index3: 0,
                end3: 99,
                PayOptions:[],
                MerchantID: localStorage.getItem("MerchantID"),
                sportProjects:[],
                isLoading:false,
            }
        },
        filters: {
            imgc(url) {
                // console.log(url)
                let urlc =
                    window.g.baseUrl +
                    "/api/v1/OpenApi/PullImage?PicName=" +
                    url +
                    "&PathType=2";
                // console.log(urlc);
                return urlc;
            }
        },
        props: {
            msg: String,
        },
        created() {
            this.getMealInfo()
            // this.GetDataDictionaryInfo()
        },
        methods:{
            onRefresh() {
                setTimeout(() => {
                    Toast('刷新成功');
                    this.isLoading = false;
                    this.count++;
                    this.loading = false
                    this.finished = false
                }, 1000);

            },
            toTicketDesc(item){
                let amount = item.reserve4
                if(item.reserve9 != null || item.reserve9 != '')
                    amount = item.reserve4 - item.reserve9
                router.push({name:'会员卡购买', params:{mealName: item.mealName, reserve5: item.reserve5, reserve6: item.reserve6, reserve7: item.reserve7, mealTypeID: item.id, price: amount,
                    effectiveDays: item.effectiveDays, limitTimes: item.limiteTimes}})
            },
            getMealInfo(){
                this.$axios
                    .request({
                        url: "/api/v1/WeChat/GetMealInfo",
                        methods: "get",
                        params: {
                            MerchantID: localStorage.getItem("MerchantID"),
                        },
                    })
                    .then((res) => {
                        console.log(res);
                        if (res.data.success) {
                            res.data.models.forEach(ele => {
                                ele.count = 0
                            })
                            this.sportProjects = res.data.models;
                            //赋值
                        } else {
                            this.sportProjects = []
                            // Toast(res.data.message)
                        }

                    }).catch((err) => {
                    Toast(err)
                })
            },

        }
    }
</script>

<style scoped>

</style>