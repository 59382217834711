<script src="../axios/axios.js"></script>
<template>
    <div style="padding-bottom: 40px;overflow: hidden;" :element-loading-text="title"
        v-loading.fullscreen.lock="loading" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- 文字较长时，通过禁用 scrollable 属性关闭滚动播放 -->
        <!-- <van-nav-bar :title="siteName + '预约下单'" left-text="返回" left-arrow @click-left="onClickLeft" fixed /> -->
        <!-- <van-notice-bar text="最近去朋友家串门发现她在自家的阳台之上养了不少水果树，让我很是心动。她告诉我自己种水果不
            仅可以体会到劳动的快乐，而且最后得到的果实纯天然无污染更绿色环保，省钱还有营养，绝对是时下里最时髦的一件事。" /> -->
        <van-sticky>
            <van-steps :active="active">
                <van-step>预约时间</van-step>
                <van-step>随行人员</van-step>
                <van-step>确认订单</van-step>
                <van-step>交易完成</van-step>
            </van-steps>
        </van-sticky>
        <div v-if="active == 0 && Date7Day.length > 0">
            <div>
                <van-tabs id="ddd" @click="seldate" :ellipsis='ellipsis' line-width="80px">
                    <van-tab v-for="(item, index) in Date7Day" :key='index' :title="item.date + '(' + item.week + ')'">
                    </van-tab>
                </van-tabs>
            </div>
            <div class="content">
                <div @click="selItme(item)" v-for="item in yuyueTimeAmount" :key="item.id"
                    :class="item == indexc ? 'active' : ''">
                    <p>{{  item.startTime  }} - {{  item.endTime  }}</p>
                    <p>金额：{{  item.amount  }}</p>
                </div>
            </div>
        </div>
        <van-empty v-if="active == 0 && Date7Day.length == 0" description="无可预约运动项目列表信息" />
        <div v-if="active == 1" style="width: 100%;">
            <van-button plain style="border-radius: 3px;margin-left:8px;" type="primary" size="small"
                @click="addPeople"> 添加随行人员
            </van-button>
            <div style="margin-top:5px;color: #666;margin-left:8px;">参加人数：{{  this.peopleArr.length + 1  }}</div>
            <div style='margin-top:10px;'>
                <van-row v-for='(item, index) in  peopleArr' :key='index' class="ccc">
                    <van-col span="5">
                        <van-image width="60" @click="ImagePreview(item.base64)" height="60" :src="item.base64"
                            style='border-radius:5px;' />
                    </van-col>
                    <van-col span="19" style="position: relative;" @click="modXX(item, index)">
                        <div style="color: #777;">姓名：{{  item.username  }}</div>
                        <div style="color: #777;">证件类型：{{  item.idType | idtypeName(idtypeArr)  }}</div>
                        <div style="color: #777;">证件号码：{{  item.cret  }}</div>
                        <div style="position: absolute;top: 20px;right: 0;font-size:20px;">
                            <van-icon name="cross" @click='deletePeole(index)' />
                        </div>
                    </van-col>
                </van-row>
            </div>
        </div>

        <div v-if="active == 2">
            <van-row style="padding:4px 10px;">
                <van-col span="12">项目名称：{{  siteName  }} </van-col>
                <van-col span="12">预约时段：{{  indexc.startTime  }}- {{  indexc.endTime  }} </van-col>
                <!-- <van-col span="12" style="color: rgb(250, 167, 85);">
                    价格：{{  indexc.amount  }}
                    <div>
                    <span v-if='youhuiValue!=0 || youhuiValue!=""' style="color:#009688;font-size:18px;margin-right:5px;position: relative;top:-5px;">优惠价：{{this.youhuiValue}}</span> 
                   <span style="vertical-align: super;" :style="{textDecoration: youhuiValue ?'line-through':'none'}"> 原价:{{ indexc.amount  }}</span>
                </div>
                </van-col> -->
            </van-row>
            <van-row style="padding:4px 10px;">
                <div>
                    <span v-if='youhuiValue != 0 || youhuiValue != ""'
                        style="color:#009688;font-size:16px;margin-right:5px;position: relative;top:-5px;">优惠价：{{
                         this.youhuiValue  }}</span>
                    <span style="vertical-align: super;"
                        :style="{ textDecoration: youhuiValue ? 'line-through' : 'none' }">
                        原价:{{  indexc.amount  }}</span>
                </div>
            </van-row>
            <!-- <van-row style="padding:4px 10px;">
                <van-col span="24">预约时段：{{  indexc.startTime  }}- {{  indexc.endTime  }} </van-col>
            </van-row> -->
            <van-row style="padding:4px 10px;">
                <van-col span="24">
                    <div v-html='indexc.needingAttention'></div>
                </van-col>
            </van-row>
            <div style="margin-top:5px;padding:4px 10px;color: #666;">参加人数：{{  this.peopleArr.length + 1  }}</div>
            <div style='margin-top:10px;padding:4px 10px;'>
                <van-row v-for='(item, index) in  peopleArr' :key='index' class="ccc">
                    <van-col span="5">
                        <van-image width="60" @click="ImagePreview(item.base64)" height="60" :src="item.base64"
                            style='border-radius:5px;' />
                    </van-col>
                    <van-col span="19" style="position: relative;">
                        <div style="color: #777;margin-bottom: 10px;margin-top:5px;">姓名：{{  item.username  }}</div>
                        <div style="color: #777;margin-bottom: 10px;">证件号码：{{  item.cret  }}</div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div v-if="active == 3">
            <van-row style="padding:4px 10px;">
                <van-col span="12">项目名称：{{  siteName  }} </van-col>
                <van-col span="12" style="color: rgb(250, 167, 85);">
                    <!-- 价格：{{  indexc.amount  }} -->
                    预约时段：{{  indexc.startTime  }}- {{  indexc.endTime  }}

                </van-col>
            </van-row>
            <van-row style="padding:4px 10px;">
                <van-col span="24">
                    <span> 价格:{{  indexc.amount  }}</span>
                </van-col>
            </van-row>
            <!-- <van-row style="padding:4px 10px;">
            <van-col span="24">预约时段：{{  indexc.startTime  }}- {{  indexc.endTime  }} </van-col>
        </van-row> -->
            <van-row style="padding:4px 10px;">
                <van-col span="24">订单号：{{  payOrderNo  }}</van-col>
            </van-row>
            <van-row style="padding:4px 10px;">
                <van-col span="24">
                    <div v-html='indexc.needingAttention'></div>
                </van-col>
            </van-row>
            <div style="margin-top:5px;padding:4px 10px;color: #666;">参加人数：{{  this.peopleArr.length + 1  }}</div>
            <div style='margin-top:10px;padding:4px 10px;'>
                <van-row v-for='(item, index) in  peopleArr' :key='index' class="ccc">
                    <van-col span="5">
                        <van-image width="60" @click="ImagePreview(item.base64)" height="60" :src="item.base64"
                            style='border-radius:5px;' />
                    </van-col>
                    <van-col span="19" style="position: relative;">
                        <div style="color: #777;margin-bottom: 10px;margin-top:5px;">姓名：{{  item.username  }}</div>
                        <div style="color: #777;margin-bottom: 10px;">证件号码：{{  item.cret  }}</div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <!-- <div class="btn" @click='tohome'>
            <van-icon name="wap-home-o" style='font-size:24px;' />
            <p style="padding: 0px;margin: 0;">首 页</p>
        </div> -->
        <div style="margin-top:10px;text-align: right;position: fixed;bottom:0;right: 0;">
            <van-button type="primary" v-if="active == 1 || active == 2" button-text="上一步" color="rgb(0, 150, 136)"
                @click="prev">上一步</van-button>
            <van-button type="primary" v-if="(active == 0 || active == 1) && Date7Day.length > 0"
                color="rgb(0, 150, 136)" @click="next">下一步</van-button>
            <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" type="primary" v-if="active == 2"
                @click="onSubmit">提交订单</van-button>
        </div>

        <!-- <van-submit-bar v-if="(active == 0 || active == 1) &&  Date7Day.length > 0 " button-text="下一步" @submit="next" /> -->
        <!-- <van-submit-bar v-if="active == 1 || active == 2" button-text="上一步" @submit="prev" /> -->
        <!-- <van-submit-bar v-if="active == 2" :price="indexc.amount | roundNum " button-text="提交订单" @submit="onSubmit" /> -->
        <van-button v-if="active == 3" @click='tohome' type="primary"
            style='width:90%;margin-left:5%;margin-top:20px;border-radius:5px;' block>完成</van-button>

        <!-- <el-dialog :title="titlec" :visible.sync="show" width="80%" center  id="abc">
            <el-form ref="obj" :model="obj" label-width="70px">
                <el-form-item label="用户名">
                    <el-input v-model="obj.username" placeholder="用户名"></el-input>
                </el-form-item>
                <el-form-item label="证件号码">
                    <el-input v-model="obj.cret" placeholder="证件号码"></el-input>
                </el-form-item>
                <el-form-item label="上传头像">
                    <div v-if="img" style="position: relative;">
                        <van-image :src="img" width="60" height="60" />
                        <van-icon name="cross" @click='img = ""'
                            style="position:absolute;top:-6px;left:53px;background-color: #fff;border-radius: 50%;font-size: 14px;" />
                    </div>
                    <i v-else class='el-icon-plus' @click="uploadimg"
                        style='width:60px;height:60px;font-size:20px;border:1px solid #ddd;text-align:center;line-height:60px;border-radius:4px;'></i>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog> -->
        <!-- 添加随行人员 姓名，照片，证件号码-->
        <van-dialog v-model="show" :title="titlec" show-cancel-button @confirm="confirm" @cancel='cancel'>
            <van-field name="uploader" label="上传头像">
                <template #input>
                    <div v-if="img" style="position: relative;">
                        <van-image :src="img" width="60" height="60" />
                        <van-icon name="cross" @click='img = ""'
                            style="position:absolute;top:-6px;right:-7px;background-color: #fff;border-radius: 50%;font-size: 14px;" />
                    </div>
                    <van-image v-else width="60" height="60" @click="uploadimg"></van-image>
                </template>
            </van-field>
            <van-field name="radio" label="证件类型">
                <template #input>
                    <van-radio-group v-model="obj.idType" direction="horizontal">
                        <van-radio :name="item.id" v-for='item in  idtypeArr'>{{  item.dictionaryContent  }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-model="obj.username" name="用户名" label="用户名" placeholder="用户名" />
            <van-field v-model="obj.cret" name="证件号码" label="证件号码" placeholder="证件号码" />

        </van-dialog>
        <!-- 选择支付方式 -->
        <van-action-sheet v-model="show1" :actions="actions" cancel-text="取消" close-on-click-action
            @select="onSelect" />
        <!-- 图片裁剪     -->
        <div :style="show2 == '' ? aa : bb"
            style='position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,.7);'>
            <div class="cropper" style="position: relative;overflow: hidden; text-align: center;width:100%;height:100%;"
                :style="obj">
                <van-image :src="img" class="img" style="position: absolute;width: 100%;height:100%;left: 0;top: 0;" />
                <div v-if="!img" style="position:relative;margin-top:20%;" @click="show2 = ''"> 返回随行人员
                </div>
                <h5-cropper @getbase64="getbase64Data" :option="option" @get-file="getFile"></h5-cropper>
            </div>
        </div>
    </div>
</template>
<script>
// import { Toast } from 'vant';
import CryptoJS from "crypto-js";
let key = '270AEF9A6F5A80EA68648CC0';

const decrypt = (ciphertext, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrpted = CryptoJS.TripleDES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrpted.toString(CryptoJS.enc.Utf8)
}
const encry = (dataStr, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};
// let cAPPID = 'wxddf1961a13ac0db4';
// let cAppSecret = 'JCA78956859862659565232696252598'; //支付密钥
// let cmch_id = '1629754823';
let cAPPID = '';
let cAppSecret = ''; //支付密钥
let cmch_id = '';
import Vue from 'vue';
import NP from 'number-precision'
import { Tab, Tabs, Button, NoticeBar, SubmitBar, NavBar, Sticky, Toast, Step, Steps, Dialog, Uploader, Loading, ContactCard, Col, Row, Image as VanImage, ImagePreview, Overlay, Empty, ActionSheet, RadioGroup, Radio } from 'vant';
Vue.use(Tab); Vue.use(ContactCard); Vue.use(Empty); Vue.use(Loading);
Vue.use(Uploader); Vue.use(Col); Vue.use(ActionSheet); Vue.use(Radio); Vue.use(RadioGroup);
Vue.use(Row); Vue.use(VanImage); Vue.use(ImagePreview); Vue.use(Overlay);
Vue.use(Tabs); Vue.use(Sticky);
Vue.use(Button);
Vue.use(NoticeBar); Vue.use(Step);
Vue.use(Steps);
Vue.use(SubmitBar);
Vue.use(NavBar);
Vue.use(Toast); Vue.use(Dialog);
import H5Cropper from "vue-cropper-h5";
export default {
    name: 'yueOrder',
    data: () => {
        return {
            aa: { 'z-index': '-1', 'opacity': 0 },
            bb: { 'z-index': '3999', 'opacity': 1 },
            indexc: 1,
            active: 0,
            peopleArr: [],
            yuyueTimeObj: '',
            ellipsis: false,
            youhuiValue: '',
            yuyueTimeAmount: [], payOrderNo: '',
            Date7Day: '',
            show1: false,
            show2: false,
            idtypeArr: '',
            actions: [{ name: '微信' }, { name: '账户', subname: '余额' }],
            show: false,
            obj: {
                username: '',
                cret: '',
                photo: [],
                base64: '',
                idType: '',
                idTypeName: ''
            },
            loading: false,
            title: '',
            titlec: '添加随行人员',
            siteName: '',
            sd: '',
            yytime: '',
            yyamount: '',
            yyzy: '', user: '',
            obj2: {

            },
            payresult: {

            },
            paycc: {},
            moditem: '',
            modIndex: '',
            type: '',
            //剪切图片上传
            crap: false,
            previews: {},
            option: {
                //         ceilbutton: true,//操作按钮是否在顶部    
                // // fixedNumber: this.fixedNumber,//截图框的宽高比例    
                // canMoveBox: true,//截图框能否拖动  
                fixedBox: false,//固定截图框大小 不允许改变 
                // centerBox: true,//截图框是否被限制在图片里面 
                fixed: false,//是否开启截图框宽高固定比例    ,
                // img: 'http://geren.yi-school.top/images/logo.png', // 裁剪图片的地址
                // info: true, // 裁剪框的大小信息
                // outputSize: 1, // 剪切后的图片质量（0.1-1）
                // full: true, // 输出原图比例截图 props名full
                // outputType: 'jpg', // 裁剪生成额图片的格式
                canMove: false,  // 能否拖动图片
                // original: false,  // 上传图片是否显示原始宽高
                canMoveBox: true,  // 能否拖动截图框
                // canMove:true,
                // autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 180,
                autoCropHeight: 180,
                // centerBox:true,
                // fixedBox: false // 截图框固定大小
            },
            img: "",
            avatarImage: "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=319223871,3949806150&fm=26&gp=0.jpg",
            form: ''
        }
    },
    props: {
        msg: String,
    },
    components: {
        H5Cropper
    },
    mounted() {
        let sd = this.$route.query.id;
        let siteName = this.$route.query.siteName;
        console.log(sd, siteName)
        this.getSportProjectInfo(sd)
        this.siteName = siteName;
        this.user = JSON.parse(localStorage.getItem('user'))
        this.sd = sd;
        this.GetDataDictionaryInfo();
        this.getMemeberDetails();
        this.GetPaymentParameters();
        // this.testFindOrder('','16608408891973468439438374629')

    },
    filters: {
        roundNum: function (value) {
            console.log(value, Number(value))
            // 四舍五入 小数点后保留两位
            return 0.01;
        },
        idtypeName(name, arr) {
            console.log(name, arr)
            let arr1 = arr.filter(ele => ele.id == name)
            return arr1[0].dictionaryContent || ''
        }
    },
    methods: {
        GetPaymentParameters() {
            console.log(14342)
            let payParameters = localStorage.getItem('payParameters')
            let payParametersJSON = payParameters && JSON.parse(payParameters)
            // console.log(payParametersJSON)
            cAPPID = payParametersJSON.weChatAppID;
            cAppSecret = payParametersJSON.weChatKey; //支付密钥
            cmch_id = payParametersJSON.weChatMchID;
            // console.log(cAPPID,cAppSecret ,cmch_id)
            // this.loading = true;
            // this.$axios
            //     .request({
            //         method: "GET",
            //         url: "/api/v1/OpenApi/GetPaymentParameters",
            //         params: {
            //             merchantID: localStorage.getItem("MerchantID"),
            //         },
            //     })
            //     .then((res) => {
            //         console.log(res.data);
            //         let obj = {
            //             alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
            //             alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
            //             alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
            //             merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
            //             merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
            //             weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key),
            //             weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key),
            //             weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
            //             weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
            //             weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
            //             weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
            //         }
            //         // console.log({...res.data,...obj});
            //         // this.$EventBus.$emit("asd", {
            //         //   ...res,
            //         //   title: "支付参数",
            //         //   ctx: "获取支付参数信息" + "",
            //         // });
            //         console.log(obj)
            //         cAPPID = obj.weChatAppID;
            //         cAppSecret = obj.weChatKey; //支付密钥
            //         cmch_id = obj.weChatMchID;
            //         this.loading = false;
            //         // this.form = res.data;
            //         this.form = obj;
            //     });
        },
        async getAvatarImageUrl1(file) {
            var formData = new FormData();
            formData.append('file', file); // 固定格式
            const res = await upload(formData)
            if (res) {
                this.img1 = (res.data[0])
                console.log(res);
            }
        },
        uploadimg() {
            this.show2 = true;
            let aaa = document.getElementsByClassName('upbtn')[1]
            aaa.click();
            //  console.log('2222222222222222222,',aaa)

        },
        getFile() {
            console.log(3412434)
            this.option.img = ''
        },
        getbase64Data(data) {
            // console.log(3412434)
            console.log(data)
            this.obj.base64 = data;
            this.obj.photo = [];
            this.img = data;
            this.obj.photo.push({ content: data });
            this.show2 = '';
            console.log(this.show2)
        },
        ccc() {
            console.log('确定一下')
            this.$refs.cropper.getCropData(data => {
                // do something
                console.log(data)
                this.obj.base64 = data;
                this.obj.photo = [];
                this.img = data;
                this.obj.photo.push({ content: data });
                this.show2 = '';
                console.log(this.show2)
            })
        },
        // 实时预览函数
        realTime(data) {
            this.previews = data;
        },
        //图片加载的回调 imgLoad 返回结果success, error
        imgLoad(msg) {
            console.log('imgLoad')
            console.log(msg)
        },
        GetDataDictionaryInfo() {
            let datac = localStorage.getItem("DictionaryInfo")
            if (datac) {
                let models = JSON.parse(datac);
                var type = ''; let payArr = [], idtypeArr = [];
                let parentNode = models.filter(ele => ele.dictionaryContent == '证件类型');
                console.log(parentNode)
                models.forEach((item) => {
                    // 身份证
                    // console.log(item)
                    if (item.dictionaryContent == '微信') {
                        type = item;
                        payArr.push({ ...item, name: item.dictionaryContent })
                    }
                    if (item.dictionaryContent == '账户') {
                        type = item;
                        payArr.push({ ...item, name: item.dictionaryContent, subname: '' })
                    }
                    if (item.parentNode == parentNode[0].node) {
                        idtypeArr.push(item)
                    }
                });
                this.type = type.id;
                this.actions = payArr;
                this.obj.idType = idtypeArr[0].id
                this.idtypeArr = idtypeArr;
            } else {
                this.type = "";
                // this.$message.error(res.data.message);
            }
            return false;
            this.$axios
                .request({
                    url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                    method: "get",
                    params: {
                        node: 999,
                        MerchantID: localStorage.getItem("MerchantID"),
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {
                        var type = ''; let payArr = [], idtypeArr = [];
                        let parentNode = res.data.models.filter(ele => ele.dictionaryContent == '证件类型');
                        console.log(parentNode)
                        res.data.models.forEach((item) => {
                            // 身份证
                            // console.log(item)
                            if (item.dictionaryContent == '微信') {
                                type = item;
                                payArr.push({ ...item, name: item.dictionaryContent })
                            }
                            if (item.dictionaryContent == '账户') {
                                type = item;
                                payArr.push({ ...item, name: item.dictionaryContent, subname: '' })
                            }
                            if (item.parentNode == parentNode[0].node) {
                                idtypeArr.push(item)
                            }
                        });
                        this.type = type.id;
                        this.actions = payArr;
                        this.obj.idType = idtypeArr[0].id
                        this.idtypeArr = idtypeArr;
                    } else {
                        this.type = "";
                        // this.$message.error(res.data.message);
                    }
                    console.log(this.type)
                });
        },
        modXX(item, index) {
            console.log(item)
            this.titlec = '修改随行人员',
                this.obj = item;
            this.show = true;
            this.moditem = item;
            this.img = item.base64;
            this.modIndex = index;
        },
        fixedAmount(amount) {
            console.log(amount)
            let a = Number(amount)
            console.log(a)
            return a;
        },
        seldate(name, title) {
            console.log(name, title)
            this.changedateIndex(name)
        },
        deletePeole(index) {
            console.log(index)
            this.peopleArr.splice(index, 1)
        },
        ImagePreview(src) {
            if (src) {
                ImagePreview([src]);
            }
        },
        cancel() {
            console.log('取消', this.obj)
            this.obj = {
                username: '',
                cret: '',
                // idType: '',
                photo: [],
                base64: ''
            }
            this.obj.idType = this.idtypeArr[0].id
        },
        confirm() {
            console.log('确定', this.obj)
            this.loading = true;
            this.$nextTick(() => {
                this.loading = false;
            });
            if (this.titlec == '修改随行人员') {
                this.peopleArr[this.modIndex] = this.obj;
            } else {
                this.peopleArr.push(this.obj)
            }

            this.obj = {
                username: '',
                cret: '',
                // idType: '',
                photo: [],
                base64: ''
            }
            this.obj.idType = this.idtypeArr[0].id
            this.show = false
        },
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            this.show2 = true;
            this.img = file.content;
            console.log(file, this.img);
            this.obj.base64 = file.content;

        },
        addPeople() {
            console.log('添加随行人员名单')
            this.show = true;
            this.img = '';
            this.titlec = '添加随行人员'
        },
        next() {
            //校验上一步是否完成
            if (this.active == 0 && this.indexc < 0) {
                this.$message('请选择预约时间')
                return false
            }
            this.active++;
        },
        prev() {
            //校验上一步是否完成
            this.active--;
        },
        getSportProjectInfo(SportProjectID = '') {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetSportProjectList?Orderby",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        SportProjectID: SportProjectID,
                        StartIndex: 0,
                        EndIndex: 10
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        let dateArr = [];
                        if (res.data.models.length > 0) {
                            this.yuyueTimeObj = [];
                            this.Date7Day = [];
                            if (!res.data.models) {
                                this.yuyueTimeObj = [];
                                this.Date7Day = []; //日期的范围
                                this.loading = false;
                                return false
                            }
                            let obj = {};
                            let obj2 = [];
                            let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
                            let time = new Date();
                            let dateList = res.data.models.map(item => {
                                // console.log(item.startDate.replace(/-/g,'/'))
                                let timec = new Date(item.startDate.replace(/-/g, '/'));
                                let timeSet = item.startTime.split(':');
                                timec.setHours(timeSet[0]);
                                timec.setMinutes(timeSet[1]);
                                timec.setMilliseconds(timeSet[2]);
                                let week = timec.getDay();
                                let cstartDate = item.startDate.substring(0, 10);
                                if (obj[cstartDate]) {
                                    item.week = weeks[week];
                                    obj[cstartDate].push(item)
                                } else {
                                    // console.log(timec.getTime() >= time.getTime, timec.getTime(), time.getTime())
                                    if (timec.getTime() >= time.getTime()) {
                                        obj2.push({ date: cstartDate, week: weeks[week] })
                                        item.week = weeks[week];
                                        obj[cstartDate] = [item]
                                    }
                                }
                                // console.log(obj,obj2)
                                // item.x = item.endDate.split(' ')[0].replace(/\//g,'')
                                // item.x = this.dateFormat1(item.endDate)
                                // console.log(item.x,'$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
                                return item
                            })
                            this.yuyueTimeObj = obj;
                            this.Date7Day = obj2.reverse(); //日期的范围
                            console.log(this.yuyueTimeObj)
                            // console.log(obj,this.Date7Day,obj2)
                            this.changedateIndex(0)
                            this.loading = false;
                        }
                    } else {
                        this.yuyueTimeObj = [];
                        this.Date7Day = []; //日期的范围
                    }

                }).catch((err) => {
                    console.log(err)
                })
        },
        changedateIndex(index) {
            this.indexc = -1;
            //  yytime:'',
            // yyamount:'',
            // yyzy:''
            if (this.yuyueTimeObj.length == 0 || this.Date7Day.length == 0) {

                return false;
            }
            let curDate = this.Date7Day[index].date;
            let ctx = this.yuyueTimeObj[curDate];
            this.yuyueTimeAmount = ctx;
            this.yytime = ctx[0]
            console.log(ctx);
            // this.form.orderDate = this.Date7Day[index].date.replace(/-/g, '')
            // this.dateListLy = this.dateList.filter(item => {
            //   return item.x == this.form.orderDate
            // })
        },
        selItme(item) {
            console.log(item)
            this.indexc = item;
            console.log(this.indexc)
            this.GetDiscountAmount().then(resc => {
                console.log(resc)
                if (resc < 100) {
                    this.youhuiValue = NP.times(this.indexc.amount, NP.divide(resc, 100))
                } else {
                    this.youhuiValue = ''
                }
                console.log(this.youhuiValue)
            })
        },
        onClick(name, title) {
            console.log(title);
        },
        onSelect(item) {
            // 默认情况下点击选项时不会自动收起
            // 可以通过 close-on-click-action 属性开启自动收起
            //   this.show = false;
            console.log(item);
            let { dictionaryContent, id } = item;
            this.type = id;
            console.log(dictionaryContent, id)
            if (dictionaryContent == '微信') {
                this.startwxpay(1);
            } else if (dictionaryContent == '账户') {
                this.startwxpay(2);
            }
        },
        startwxpay(num) {
            this.obj2 = '';
            // this.updateotherxx();
            // this.active = 3;
            // this.$message.warning('发起支付');
            console.log(this.indexc)
            this.loading = true;
            this.title = '正在创建预约订单'
            let obj = {
                id: this.guid(),
                merchantID: localStorage.getItem('MerchantID'),
                memberID: JSON.parse(localStorage.getItem('user')).id,
                sportProjectID: this.sd,
                priceListID: this.indexc.id,
                payType: 0,
                orderNo: this.guid(),
                orderDate: this.dateFormat1(this.indexc.startDate), //yyyyMMdd
                orderTime: this.dateFormat(), //yyyyMMddHHmmss
                startTime: this.indexc.startTime.replace(/:/g, ''), //HHmm
                endTime: this.indexc.endTime.replace(/:/g, ''), //HHmm
                amount: this.indexc.amount,
                orderType: 1,
                memberType: 0
            }
            this.obj2 = obj;
            console.log(obj);
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/WriteOrderInfo",
                    data: { ...obj },
                })
                .then((res) => {
                    if (res.data.success) {
                        //发起支付
                        if (num == 1) {  //微信
                            // this.rechargeOrder(obj);
                            this.createWXOrder(obj)
                        } else if (num == 2) {  //账户
                            this.accountrechargeOrder(obj);
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                    // this.loading =false;
                    // this.WriteRechargeInfo(a, desc);
                });
        },
        GetDiscountAmount() {
            console.log(this.indexc)
            return new Promise((resolve, reject) => {
                this.$axios
                    .request({
                        method: "GET",
                        url: "/api/v1/OpenApi/GetDiscountAmount",
                        params: {
                            SportProjectID: this.sd,
                            merchantID: localStorage.getItem('MerchantID'),
                            AppointmentTime: this.indexc.startDate.split(' ')[0].replace(/-/g, '') + this.indexc.startTime.replace(/:/g, ''),
                            MemberType: 0,
                        },
                    })
                    .then((res) => {

                        console.log(res.data)
                        if (res.data.success) {  //有优惠项目
                            resolve(res.data.value)
                        } else {  //无优惠项目
                            resolve(res.data.value)
                        }
                    });
            })

        },
        onSubmit() {
            if (cAPPID !== null && cAppSecret !== null && cmch_id !== null) {
                console.log('我可以')
            } else {
                console.log('我不行')
                this.$message({
                    message: '缺少相关支付参数，请联系管理员',
                    type: 'warning'
                });
                return false;
            }
            console.log('提交')
            this.GetDiscountAmount().then(resc => {
                console.log(resc)
                let balance = this.user.balance;
                let needAmount = this.indexc.amount;
                needAmount = NP.times(needAmount, NP.divide(resc, 100))
                this.indexc.amount = needAmount
                console.log(balance, needAmount, this.indexc.amount)
                // return false;
                if (NP.minus(balance, needAmount) < 0) {  //余额不足
                    console.log(this.actions);
                    this.actions[0].subname = '余额不足'
                    this.actions[0].disabled = true;
                } else {
                    this.actions[0].subname = '余额' + balance
                    this.actions[0].disabled = false;
                }
                this.show1 = true;
                return false;
            }).catch(err => {

            })




        },
        accountrechargeOrder(obj) {
            this.updateotherxx(obj, 'nopaymentResult')
        },
        rechargeOrder(obj) {
            console.log(obj)
            let { memberID, merchantID, orderNo, amount, payType, orderTime } = obj;
            // this.$toast.loading('第一步，去后台服务器正在创建订单')
            // console.log('第一步，去后台服务器正在创建订单')
            // return false;
            this.loading = true;
            this.title = '正在创建支付订单'
            this.$axios
                .request({
                    url: "/api/v1/WeChat/RechargeOrder",
                    method: "get",
                    params: {
                        memberID, merchantID, orderNo, amount, payType, orderTime
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {
                        this.createWXOrder(obj)
                    } else {

                        // this.$message.error(res.data.message);
                    }
                    this.loading = false;
                    // this.paymentResult(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    // this.wxpayFunc(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime);
                    // this.createWXOrder(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    console.log(this.type)

                });
        },
        createWXOrder(nobj) {
            // Toast('第二步，去node服务器生成微信订单，去支付')
            // this.$toast.loading({
            //     message: '第二步，去node服务器生成微信订单，去支付',
            //     forbidClick: true,
            // });
            console.log(nobj)
            this.loading = true;
            this.title = '生成微信订单'
            let that = this;
            let local = 'http://43.138.48.29/token/wxresult';
            let obj = {
                appid: cAPPID,
                mch_id: cmch_id,
                key: cAppSecret,
                // mch_id:'1305638280',
                openid: localStorage.getItem('openid'),
                timestamp: nobj.OrderTime,
                out_trade_no: nobj.OrderNo,
                total_fee: nobj.amount * 100, //写死
                nonceStr: this.randomString(16),
                // body: 'JSAPI支付',
                body: this.indexc.sportProjectName + ' 运动场地预约支付' + this.indexc.amount,

                notify_url: encodeURIComponent(local), //异步回调地址，去通知服务器的地址
                // notify_url: local
            }
            // Toast('正在生成微信订单')
            // this.$toast.loading({
            //     message: '正在生成微信订单',
            //     forbidClick: true,
            // });
            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/testwxpay",
                    method: "POST",
                    data: {
                        ...obj
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)

                    console.log(res.data.success);
                    //发起支付
                    function onBridgeReady(res) {
                        // alert(JSON.stringify(res))
                        // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                        let paySign = that.$md5(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=JCA78956859862659565232696252598`).toUpperCase();
                        console.log(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=JCA78956859862659565232696252598`)
                        console.log(paySign)
                        console.log({
                            "appId": cAPPID,     //公众号ID，由商户传入     
                            "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr": res.nonceStr, //随机串     
                            "package": res.package,
                            "signType": "MD5",         //微信签名方式：     
                            "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                        })
                        WeixinJSBridge.invoke(
                            'getBrandWCPayRequest', {
                            "appId": cAPPID,     //公众号ID，由商户传入     
                            "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr": res.nonceStr, //随机串     
                            "package": res.package,
                            "signType": "MD5",         //微信签名方式：     
                            "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                        },
                            function (resc) {
                                // that.updateotherxx(res);
                                // alert(JSON.stringify(resc))
                                if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    // 更新预约订单，增加新的人员，更新支付订单
                                    that.updateotherxx(res)
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                    // alert('支付过程中用户取消')
                                    this.$message({
                                        message: '支付过程中用户取消',
                                        type: 'warning'
                                    });
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                    // alert('支付失败')                            
                                    this.$message.error('支付失败');
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                that.value = '';
                                // Toast('查询订单信息')
                                // that.testFindOrder(newResc, OrderNo)
                            });
                    }
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                        onBridgeReady(res.data.data);
                    }
                    if (res.data.return_msg == 'OK') {
                        console.log('测试成功,去查询订单')
                        // this.wxpayFunc(res, OrderNo, 5.51 * 100);

                    } else {
                        console.log('我暂时无法完成支付')
                        // this.$message.error(res.data.message);
                    }
                    that.loading = false;


                });
        },
        updateotherxx(res, par) {
            console.log(res, this.obj2, this.peopleArr)
            this.AddEntourage(this.obj2, res, par)     //更新随行人员
            this.loading = true;
            this.title = '正在更新订单信息,请稍等'

            this.getMemeberDetails();                  //重新查询
        },
        testFindOrder(res, OrderNo) { //16608411612347050235143755968
            console.log('我去查询订单')
            this.loading = true;
            this.title = '生成查询订单'
            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/testpayOrder",
                    method: "POST",
                    data: {
                        appid: cAPPID, mch_id: cmch_id, key: cAppSecret,
                        nonceStr: this.randomString(16), out_trade_no: res.out_trade_no
                    },
                })
                .then((resc) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(resc.data);
                    // this.$toast(res.data.err_code_des)
                    if (resc.data.return_msg == 'OK') {
                        console.log('测试成功,去查询订单')
                        this.UpdateOrderPayState(this.obj2, resc)
                    } else {
                        console.log('我错了')
                        // this.$message.error(res.data.message);
                    }
                    this.loading = false;
                });
        },
        paymentResult(res, resc) {
            console.log(res, resc)
            // alert(JSON.stringify(res))
            this.$axios
                .request({
                    url: "/api/v1/WeChat/PaymentResult",
                    method: "get",
                    params: {
                        memberId: res.memberID,
                        MerchantID: res.merchantID,
                        OrderNo: res.orderNo,
                        PayOrderNo: resc.data.transaction_id,
                        Amount: Number(res.amount),
                        ReceiptsAmount: Number(res.amount),
                        PayTime: res.orderTime,
                        CreateBy: localStorage.getItem('wechatUserID')
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {
                        // this.$message({
                        //     message: res.data.message,
                        //     type: 'success'
                        // });
                    } else {
                        this.$message.error(res.data.message.substring(0, 15));
                        // this.$toast(res.data.message.substring(0, 15))
                        // this.$message.error(res.data.message);
                    }

                    console.log(this.type)
                });
        },
        AddEntourage(resc, ref = '', par) {
            if (this.peopleArr.length == 0) {
                if (!par) {  //微信
                    this.testFindOrder(ref)
                } else {
                    this.UpdateOrderPayState(this.obj2, ref)   //更新预约订单信息

                }
                return false
            }
            let nArr = []
            this.peopleArr.forEach(ele => {
                console.log(ele)
                let obj = {};
                obj.orderID = this.obj2.id;
                obj.orderNo = ref.out_trade_no || ref.orderNo;
                obj.entouragePic = ele.base64 ? ele.base64.split(",")[1] : '';
                obj.entourageName = ele.username;
                obj.idno = ele.cret;
                obj.idType = ele.idType
                nArr.push(obj)
                // let s = this.options3.filter(elec => elec.id == ele.idType)
                // if (s.length > 0) {
                //     ele.idName = s[0].dictionaryContent;
                // } else {
                //     ele.idName = '';
                // }

            })
            console.log(nArr, this.peopleArr);
            // return false;
            // let payOrderNo = res == '现金' ? '' : res.a;
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/AddEntourage",
                    data: {
                        merchantID: resc.merchantID,
                        models: nArr
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        console.log(res.data);
                        this.active = 3;
                        this.loading = false;
                        // this.$message({
                        //     message: res.data.message,
                        //     type: 'success'
                        // });
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                    if (!par) {  //微信
                        this.testFindOrder(ref)
                    } else {
                        this.UpdateOrderPayState(this.obj2, ref)   //更新预约订单信息
                    }
                    this.loading = false;
                });
        },
        // 更新支付订单状态信息
        UpdateOrderPayState(a, ref) {
            console.log(ref.data)
            let isPay = 0; let payOrderNo = ''
            if (ref.data && ref.data.trade_state == 'SUCCESS') {
                isPay = ref.data.trade_state == 'SUCCESS' ? 1 : 0;
                payOrderNo = ref.data.transaction_id
            } else {
                isPay = 1;
                payOrderNo = ref.orderNo
            }
            this.payOrderNo = payOrderNo;
            console.log(a, {
                id: a.id,
                payState: isPay,
                merchantID: a.merchantID,
                ReceiptsAmount: Number(a.amount),
                isPay: isPay,
                MemberType: a.memberType,
                payTime: this.dateFormat(),
                payType: this.type,   //0 微信 1 支付宝
                payOrderNo: payOrderNo,
            })
            // let payOrderNo = res == '现金' ? '' : res.a;
            // return false;
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/OpenApi/UpdateOrderPayState",
                    data: {
                        id: a.id,
                        payState: isPay,
                        merchantID: a.merchantID,
                        ReceiptsAmount: Number(a.amount),
                        isPay: isPay,
                        MemberType: a.memberType,
                        payTime: this.dateFormat(),
                        payType: this.type,   //0 微信 1 支付宝
                        payOrderNo: payOrderNo,
                    },
                })
                .then((res) => {
                    // console.log(this.ryList)
                    if (res.data.success) {
                        console.log(res.data, this.paycc);
                        this.$message({
                            message: '预约成功',
                            type: 'success'
                        });
                        this.loading = false;
                        this.active = 3;

                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.message || "操作失败",
                        });
                    }
                    this.loading = false;
                });
        },
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz",
                a = t.length,
                n = "";
            for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        tohome() {
            console.log('去往首页')
            this.$router.push('/index')
        },
        onClickLeft() {
            console.log('返回', this.show, this.show1, this.show2);

            this.$router.back();
        },
        guid() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return S4() + S4() + S4() + S4() + S4() + S4();
        },
        dateFormat() {
            var datec = new Date();
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            var hours =
                datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
            var minutes =
                datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
            var seconds =
                datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
            // 拼接
            return ''+year + month + day + hours + minutes + seconds;
        },
        getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                    } else {
                        localStorage.setItem('user', '')
                    }

                });
        },
        dateFormat1(dateq) {
            var datec = new Date(dateq.replace(/-/g, '/'));
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            // var hours =
            //   date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            // var minutes =
            //   date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            // var seconds =
            //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            // 拼接
            return ''+year + month + day
        },
        // onClickRight() {
        //   Toast('按钮');
        // },
    },
}
</script>
<style scoped>
#abc /deep/ .el-dialog__header {
    padding: 10px 20px 10px;
}

#abc /deep/ .el-dialog__header .el-dialog__title {
    font-size: 16px;
}

#abc /deep/ .el-dialog--center .el-dialog__body {
    padding: 10px 20px 10px;
}

#abc /deep/ .el-dialog__footer {
    padding: 0 10px 10px;
}

.van-icon {
    font-size: 14px;
}

.ccc /deep/ img {
    border-radius: 5px;
}

.ccc {
    position: relative;
    padding: 10px 0;
}

.ccc::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
    background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
    background-size: auto;
    background-size: 80px;
    content: '';

}

.btn {
    position: fixed;
    height: 50px;
    width: 60px;
    right: 0px;
    padding-top: 10px;
    background-color: #fff;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 50%;
    top: 60%;
    text-align: center;
}

.content {
    display: flex;
    flex-wrap: wrap;
}

.content>div {
    border-radius: 5px;
    border: 1px solid #ddd;
    margin: 5px;
    text-align: center;
    padding: 0 1px;
}

.content>div.active {
    background-color: #e46b54;
    color: #fff;
}

.content p {
    padding: 5px 2px;
    margin: 0;
}

#ddd {
    margin: 15px 0;
    padding: auto 0;
}

#ccc /deep/ .van-tabs__wrap,
#ddd /deep/ .van-tabs__wrap {
    height: 40px !important;
}

#ccc /deep/ .van-tabs__nav--card,
#ddd /deep/ .van-tabs__nav--card {
    border: 1px solid #ddd;
}

#ccc /deep/ .van-tabs__nav--card .van-tab.van-tab--active {
    color: #fff;
    background-color: #01adfb;
    ;
}

#ddd /deep/ .van-tab.van-tab--active {
    color: #01adfb;
    background-color: #f4f8fc;
}

#ccc /deep/ .van-tabs__nav--card,
#ddd /deep/ .van-tabs__nav--card {
    height: 40px;
    line-height: 40px;
    border-radius: 24px;
}

#ddd /deep/ .van-tabs__line {
    background-color: #01adfb;
}

#ccc /deep/ .van-tabs__nav--card .van-tab,
#ddd /deep/ .van-tabs__nav--card .van-tab {
    /* background-color: #01adfb; */
    background-color: rgb(246, 246, 246);
    /* color: #fff; */
    color: #7e7e7e;
    border-right: 1px solid #fff;
}

.tit {
    text-align: left;
    padding: 0 10px;
    font-size: 14px;
    margin: 5px 0;
    text-indent: 2em;
}

.van-card__footer {
    height: 4px;
}

* {
    font-size: 14px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
