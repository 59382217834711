<template>
  <div style="padding-bottom: 30px;">
    <!-- <van-nav-bar :title="data.siteName+'预约详情页'" left-text="返回" left-arrow @click-left="onClickLeft" fixed /> -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in imgs" :key="item">
        <img style="height:200px;width: 100%;" fit="contain" :src="item | imgc" alt="">
      </van-swipe-item>
    </van-swipe>
    <div
      style='display:flex;justify-content: space-between;height:40px;border-bottom: 1px solid #ddd;align-items: center;padding:0 10px;'>
      <div style="font-size:16px;font-weight:800;">全部</div>
      <div style="color:#f26a40;">
        <!-- <van-icon name="location" style='font-size:18px;' /> <span style="font-size:14px;margin:0 5px;">一键导航</span>
        <van-icon name="arrow" /> -->
      </div>
    </div>
    <div class="tit">
       <div v-html='data.needingAttention'></div> 
    </div>
    <!-- <div>
      <div class='titHead'>场所类型</div>
      <div class="titbodybox">
        <div class="box">足球</div>
        <div class="box">羽毛球</div>
        <div class="box">篮球</div>
        <div class="box">网球</div>
      </div>
    </div> -->
    <!-- <div>
      <div class='titHead'>场所服务</div>
      <div class="titbodybox">
        <div class="box" style="background-color: #fff;">其他服务:</div>
        <div class="box">测试服务</div>
        <div class="box">测试服务</div>
      </div>
    </div> -->
    <div>
      <div class='titHead'>其他信息</div>
      <div class="titbody">
        <p>【<span style="color:#f26a40 ;">站点名称</span>】</p>
        <p>{{data.siteName}}</p>
        <p>【<span style="color:#f26a40 ;">项目名称</span>】</p>
        <p>{{data.sportProjectName}}</p>
        <p>【<span style="color:#f26a40 ;">开放时间</span>】</p>
        <p>{{data.startTime}} -- {{data.endTime}}</p>
        <!-- <p>【<span style="color:#f26a40 ;">详细地址</span>】</p>
        <p>为全面落实疫情期间的防空工作，请入场佩戴口罩，配合现场工作人员做好等级为全面落实疫情期间的防空工作，请入场佩戴口罩，配合现场工作人员做好等级</p>
        <p>【<span style="color:#f26a40 ;">详细地址</span>】</p>
        <p>为全面落实疫情期间的防空工作，请入场佩戴口罩，配合现场工作人员做好等级为全面落实疫情期间的防空工作，请入场佩戴口罩，配合现场工作人员做好等级</p> -->
      </div>
    </div>
    <div style="text-align: center;margin:15px 0;">
      <van-button @click="toyue"
        style="width:80%;height:40px;font-size:16px;border-radius:50px;background-color: #f26a40;border:none;"
        size="mini" type="info">立即预约</van-button>
    </div>
  </div>
</template>
<script>
// import { Toast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem, TabbarItem, Tab, Tabs, Card, Button, Icon, NavBar, Toast } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Card);
Vue.use(Button);
Vue.use(Icon); Vue.use(Toast);
import { Message } from 'element-ui';
export default {
  name: 'HelloWorld',
  data: () => {
    return {
      // imgs: ['https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242306111155-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661158064&t=ed4860b8b12237fae8ff51f51679d462',
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F052420110515%2F200524110515-2-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661158064&t=e5bcc20b7a23927077f2ab336033cdbf',
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242312005c1-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661158064&t=7c754c5dda36c01fbd3eec4e83b3ea54',
      //   'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1113%2F052420110515%2F200524110515-11-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1661158064&t=0b5c43589ed67055775330fcdb84e19f'],

      imgs: [],
      titles: ['全部', '足球', '篮球', '羽毛球'],
      active: 0,
      item:'',data:{}
    }
  },
  props: {
    msg: String,
  },
  filters: {
    imgc: function (url) {
      console.log(url)
      let urlc =
        window.g.baseUrl +
        "/api/v1/OpenApi/PullImage?PicName=" +
        url +
        "&PathType=2";
      // console.log(urlc);
      return urlc;
    }
  },
  mounted() {
    let sd = this.$route.query.id;
    console.log(sd)
    this.getSportProjectInfo('', sd)
  },
  methods: {
    getSportProjectInfo(SportProjectNo = '', sd) {
      this.$axios
        .request({
          url: "/api/v1/WeChat/GetSportProjectInfo?Orderby",
          methods: "get",
          params: {
            merchantID: localStorage.getItem('MerchantID'),
            SportProjectNo: '',
            SiteID: '',
            StartIndex: 0,
            EndIndex: 10
          },
        })
        .then((res) => {
          console.log(res, sd);

          if (res.data.success) {
            let Narr = res.data.models.filter(ele => ele.id == sd)
            console.log(Narr)
            let imgs = [];
            this.data = Narr[0];
            if (Narr.length > 0) {
              if (Narr[0].sportProjectPic1) {
                imgs.push(Narr[0].sportProjectPic1)
              }
              if (Narr[0].sportProjectPic2) {
                imgs.push(Narr[0].sportProjectPic2)
              }
              if (Narr[0].sportProjectPic3) {
                imgs.push(Narr[0].sportProjectPic3)
              }
            }
            this.imgs = imgs;
          } else {
            this.sportProjects = []
            Toast(res.data.message)
          }

        }).catch((err) => {
          Toast(err)
        })
    },
    toyue() {
      console.log('去预约')
       this.getSportProjectInfoa(this.data.id)
      // this.$router.push({path:'/yueOder',query:{id:this.data.id,siteName:this.data.siteName}});
    },
    getSportProjectInfoa(SportProjectID = '') {
            Message.closeAll();
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetSportProjectList?Orderby",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        SportProjectID: SportProjectID,
                        StartIndex: 0,
                        EndIndex: 10
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        this.$router.push({path:'/yueOder',query:{id:this.data.id,siteName:this.data.siteName}});                        
                    } else {
                        this.yuyueTimeObj = [];
                        this.Date7Day = []; //日期的范围
                        this.$message.error(res.data.message);
                    }

                }).catch((err) => {
                    console.log(err)
                })
        },
    onClickLeft() {
      console.log('返回');
      this.$router.back();
    },
  },
}
</script>
<style scoped>
.titbody {
  padding: 3px 10px;
  font-size: 14px;
}

.titbody p {
  padding: 3px 0;
  margin: 0;
}

.tit {
  text-align: left;
  font-size: 14px;
  padding: 0 10px;
  text-indent: 2em;
  margin: 5px 0;
}

.titHead {
  font-size: 16px;
  font-weight: 800;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #ddd;
}

.titHead::before {
  content: '';
  width: 4px;
  display: inline-block;
  height: 18px;
  background-color: #f26a40;
  margin: 0 7px;
  position: relative;
  top: 3px;
}

.titbodybox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
}

.titbodybox .box {
  height: 40px;
  width: 28%;
  background-color: #f7f7f7;
  ;
  line-height: 40px;
  text-align: center;
  margin: 6px 1%;
  font-size: 14px;
}

.titbody p:nth-child(2n) {
  line-height: 23px;
  letter-spacing: 1px;
}
</style>