<template>
    <div style="background-color:#58B6FC;height:100vh;" v-loading="loading" :element-loading-text="title"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- <van-nav-bar title="在线充值" fixed /> -->
        <div style="padding:5px 20px;">
            <div
                style="text-align: center;border-top-left-radius: 5px;border-top-right-radius: 5px;padding-top:10%;width:97.5%;">
                <van-image id="img" width="80" height="80" :src="img" style='color:#fefefe;' @click='prewImg(img)' />
                <p style="margin-top:29px;text-align: center;color:aliceblue;" @click="toAddMember">{{ userName || '张 三'
                }}</p>
            </div>
            <van-row class="ds" style="text-align: center;border-radius:5px;">
                <van-col style="border-radius:5px;">
                    <div>{{idno}}</div>
                    <div style="color: #8A8C89;">卡号正常卡</div>
                </van-col>
                <van-col style="border-radius:5px;">
                    <div style="color:#0E92BF; ">{{ balance }}</div>
                    <div style="color: #8A8C89;">余额（元）</div>
                </van-col>
            </van-row>
            <van-row style="text-align: center;" class="dg">
                <van-col @click="chongzhi(0.01, 0)" :class="index == 0 ? 'active' : ''">0.01元</van-col>
                <van-col @click="chongzhi(20, 1)" :class="index == 1 ? 'active' : ''">20元</van-col>
                <van-col @click="chongzhi(50, 2)" :class="index == 2 ? 'active' : ''">50元</van-col>
            </van-row>
            <van-row style="text-align: center;" class="dg">
                <van-col @click="chongzhi(100, 3)" :class="index == 3 ? 'active' : ''">100元</van-col>
                <van-col @click="chongzhi(200, 4)" :class="index == 4 ? 'active' : ''">200元</van-col>
                <van-col @click="chongzhi(300, 5)" :class="index == 5 ? 'active' : ''">300元</van-col>
            </van-row>
            <van-cell-group style="margin:10px 0 ;border-radius:5px;width:99%;">
                <van-field v-model="value" @focus="inputmoney" placeholder="请输入金额"
                    :rules="[{
                        required: true,
                        message: '订单金额不能为空',
                        trigger: 'blur',
                    }, { pattern: /^(([1-9]{1}\d*)|(0{1}))(.\d{1,4})?$/, message: '请输入合法的金额数字，最多四位小数', trigger: 'change' }]" type='number' style="border-radius:5px;" />
            </van-cell-group>
            <van-button type="primary" size="large" @click="chongzhiFunc" plain style="border-radius:5px;width:99%;">
                微信支付</van-button>
        </div>
    </div>
</template>
<script>
import CryptoJS from "crypto-js";
let key = '270AEF9A6F5A80EA68648CC0';
const decrypt = (ciphertext, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const decrpted = CryptoJS.TripleDES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrpted.toString(CryptoJS.enc.Utf8)
}
const encry = (dataStr, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};
import Vue from 'vue';
import { Image as VanImage, Cell, CellGroup, NavBar, ImagePreview, Col, Row, Field } from 'vant';
Vue.use(VanImage);
Vue.use(Cell); Vue.use(Field);
Vue.use(NavBar);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);
let cAPPID = '';
let cAppSecret = '';
let cmch_id = '';
// let cAPPID = 'wxddf1961a13ac0db4';
// let cAppSecret = 'JCA78956859862659565232696252598'; //支付密钥
// let cmch_id = '1629754823';
export default {
    name: 'yueOrder2',

    data() {
        return {
            userName: '',
            value: '',
            index: 99, type: '',
            balance: 0, OrderTime: '', memberId: '',
            loading: false, title: '', orderId: '', img: '',
            reamount: '', idno: '', form: ''
        };
    },

    mounted() {
        // console.log(wx)
        // Toast('e991:205 appid和mch_id不匹配，请检查后再试')
        let img = localStorage.getItem('img');
        this.img = img;
        // let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        // if (user && user.id) {
        //     let { memberName, phone, balance, id } = user;
        //     this.userName = memberName + ' / ' + phone;
        //     this.balance = balance;
        //     this.memberId = id;
        // } else {
        //     this.getMemeberDetails();
        // }
        this.getMemeberDetails();
        this.GetDataDictionaryInfo();
        this.GetPaymentParameters();
        console.log(window.location)
        // this.testFindOrder()
        // this.wxpayFunc('',421342134,5.51*100);
        // this.testFindOrder()
    },

    methods: {
        GetPaymentParameters() {
            console.log(14342)
            let payParameters = localStorage.getItem('payParameters')
            let payParametersJSON = payParameters && JSON.parse(payParameters)
            console.log(payParametersJSON)
            cAPPID = payParametersJSON.weChatAppID;
            cAppSecret = payParametersJSON.weChatKey; //支付密钥
            cmch_id = payParametersJSON.weChatMchID;
            // console.log(cAPPID,cAppSecret,cmch_id)
            //             this.$axios
            //                 .request({
            //                     method: "GET",
            //                     url: "/api/v1/OpenApi/GetPaymentParameters",
            //                     params: {
            //                         merchantID: localStorage.getItem("MerchantID"),
            //                     },
            //                 })
            //                 .then((res) => {
            //                     console.log(res.data);
            //                     let obj = {
            //                         alipay_AliappId: res.data.alipay_AliappId && decrypt(res.data.alipay_AliappId, key),
            //                         alipay_Pid: res.data.alipay_Pid && decrypt(res.data.alipay_Pid, key),
            //                         alipay_public_key: res.data.alipay_public_key && decrypt(res.data.alipay_public_key, key),
            //                         merchant_private_key: res.data.merchant_private_key && decrypt(res.data.merchant_private_key, key),
            //                         merchant_public_key: res.data.merchant_public_key && decrypt(res.data.merchant_public_key, key),
            //                         weChatAppID: res.data.weChatAppID && decrypt(res.data.weChatAppID, key),
            //                         weChatAppSecret: res.data.weChatAppSecret && decrypt(res.data.weChatAppSecret, key),
            //                         weChatKey: res.data.weChatKey && decrypt(res.data.weChatKey, key),
            //                         weChatMchID: res.data.weChatMchID && decrypt(res.data.weChatMchID, key),
            //                         weChatNotify_Url: res.data.weChatNotify_Url && decrypt(res.data.weChatNotify_Url, key),
            //                         weChatSslcert_Path: res.data.weChatSslcert_Path && decrypt(res.data.weChatSslcert_Path, key),
            //                     }
            //                     // console.log({...res.data,...obj});
            //                     // this.$EventBus.$emit("asd", {
            //                     //   ...res,
            //                     //   title: "支付参数",
            //                     //   ctx: "获取支付参数信息" + "",
            //                     // });
            //                     console.log(obj)
            //                     this.loading = false;
            //                     cAPPID =obj.weChatAppID;
            // cAppSecret = obj.weChatKey; //支付密钥
            // cmch_id = obj.weChatMchID;
            //                     // this.form = res.data;
            //                     this.form = obj;
            //                 });
        },
        inputmoney(value) {
            // console.log(value)
            this.index = -1;
        },
        chongzhi(money, index) {
            console.log(money, index)
            // this.value = money;
            this.index = index;
            this.chongzhiFunc(money);
        },
        chongzhiFunc(moneyc) {
            console.log(cAPPID, cAppSecret, cmch_id)
            if (cAPPID !== null && cAppSecret !== null && cmch_id !== null) {
                console.log('我可以')
            } else {
                console.log('我不行')
                this.$message({
                    message: '缺少相关支付参数，请联系管理员',
                    type: 'warning'
                });
                return false;
            }

            let money = Number(moneyc) ? Number(moneyc) : Number(this.value);
            console.log(money)
            if (money <= 0) {
                this.$message.error('金额无效')
            } else {
                this.reamount = money;
                let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
                console.log(user, this.type)
                if (user && user.id) {
                    let { id } = user;
                    let memberId = id;
                    let MerchantID = localStorage.getItem('MerchantID')
                    let OrderNo = this.guid();
                    let Amount = money;
                    let PayType = 0;    //传值容易出问题 0 -微信 ，1--支付宝
                    let OrderTime = this.dateFormat();
                    this.OrderTime = OrderTime;
                    this.orderId = OrderNo;
                    //ReceiptsAmount新增 PayOrderNo替换	OrderNo
                    console.log(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    // Toast('可以充值')
                    this.rechargeOrder(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    // this.wxpayFunc(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime);
                } else {
                    this.getMemeberDetails(1);
                }

            }
        },
        getMemeberDetails(isc) {
            console.log(isc)
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, balance, id, idno } = res.data;
                        this.userName = memberName + ' / ' + phone;
                        this.balance = balance
                        this.memberId = id;
                        this.idno = idno;
                        if (isc == 1) {
                            console.log(isc)
                            this.chongzhiFunc();
                        }

                    } else {
                        localStorage.setItem('user', '')
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        rechargeOrder(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime) {
            // this.$toast.loading('第一步，去后台服务器正在创建订单')
            // console.log('第一步，去后台服务器正在创建订单')
            this.loading = true;
            this.title = '正在创建订单'
            this.$axios
                .request({
                    url: "/api/v1/WeChat/RechargeOrder",
                    method: "get",
                    params: {
                        memberId, MerchantID, OrderNo, Amount, PayType, OrderTime
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {

                    } else {

                        // this.$message.error(res.data.message);
                    }
                    this.loading = false;
                    // this.paymentResult(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    // this.wxpayFunc(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime);
                    this.createWXOrder(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
                    console.log(this.type)

                });
        },
        createWXOrder(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime) {
            // Toast('第二步，去node服务器生成微信订单，去支付')
            // this.$toast.loading({
            //     message: '第二步，去node服务器生成微信订单，去支付',
            //     forbidClick: true,
            // });
            console.log(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime)
            this.loading = true;
            this.title = '生成微信订单'
            let that = this;
            let local = 'http://43.138.48.29/token/wxresult';
            let obj = {
                // AppSecret: '68682020122820210304BBC686820201',
                // appid: 'wxec50473d0c0ec298',
                // mch_id: '1608150212',
                // key: '68682020122820210304BBC686820201',
                appid: cAPPID,
                mch_id: cmch_id,
                key: cAppSecret,
                // mch_id:'1305638280',
                openid: localStorage.getItem('openid'),
                timestamp: OrderTime,
                out_trade_no: OrderNo,
                total_fee: Amount * 100, //写死
                nonceStr: this.randomString(16),
                body: '在线充值' + Amount + '元',

                notify_url: encodeURIComponent(local), //异步回调地址，去通知服务器的地址
                // notify_url: local
            }
            // Toast('正在生成微信订单')
            // this.$toast.loading({
            //     message: '正在生成微信订单',
            //     forbidClick: true,
            // });
            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/testwxpay",
                    method: "POST",
                    data: {
                        ...obj
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    // alert(JSON.stringify(res.data));
                    console.log(res.data.success);
                    //发起支付
                    console.log({
                        debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: cAPPID, // 必填，公众号的唯一标识
                        timestamp: res.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.noncestr, // 必填，生成签名的随机串
                        signature: res.sign,// 必填，签名
                        jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
                    })

                    // wx.config({
                    //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    //     appId: 'wxec50473d0c0ec298', // 必填，公众号的唯一标识
                    //     timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    //     nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                    //     signature: res.data.sign,// 必填，签名
                    //     jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
                    // });
                    function onBridgeReady(res) {
                        // alert(JSON.stringify(res))
                        // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                        let paySign = that.$md5(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=JCA78956859862659565232696252598`).toUpperCase();
                        console.log(`appId=${cAPPID}&nonceStr=${res.nonceStr}&package=${res.package}&signType=MD5&timeStamp=${res.timeStamp}&key=JCA78956859862659565232696252598`)
                        console.log(paySign)
                        console.log({
                            "appId": cAPPID,     //公众号ID，由商户传入     
                            "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr": res.nonceStr, //随机串     
                            "package": res.package,
                            "signType": "MD5",         //微信签名方式：     
                            "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                        })
                        WeixinJSBridge.invoke(
                            'getBrandWCPayRequest', {
                            "appId": cAPPID,     //公众号ID，由商户传入     
                            "timeStamp": res.timeStamp,         //时间戳，自1970年以来的秒数     
                            "nonceStr": res.nonceStr, //随机串     
                            "package": res.package,
                            "signType": "MD5",         //微信签名方式：     
                            "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                        },
                            function (resc) {
                                // alert(JSON.stringify(resc))
                                if (resc.err_msg == "get_brand_wcpay_request:ok") {
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    // alert('支付成功')
                                    // that.paymentResult(res, resc)
                                    that.testFindOrder(res, resc)
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                if (resc.err_msg == "get_brand_wcpay_request:cancel") {
                                    // alert('支付过程中用户取消')
                                    this.$message({
                                        message: '支付过程中用户取消',
                                        type: 'warning'
                                    });
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                if (resc.err_msg == "get_brand_wcpay_request:fail") {
                                    // alert('支付失败')                            
                                    this.$message.error('支付失败');
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }
                                that.value = '';
                                // Toast('查询订单信息')
                                // that.testFindOrder(newResc, OrderNo)
                                // that.testFindOrder(res, resc)
                            });
                    }
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                        }
                    } else {
                        onBridgeReady(res.data.data);
                    }
                    if (res.data.return_msg == 'OK') {
                        console.log('测试成功,去查询订单')
                        // this.wxpayFunc(res, OrderNo, 5.51 * 100);

                    } else {
                        console.log('我暂时无法完成支付')
                        // this.$message.error(res.data.message);
                    }
                    that.loading = false;


                });
        },
        testFindOrder(res, OrderNo) {
            console.log('我去查询订单', res)
            this.loading = true;
            this.title = '正在查询订单'
            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/testpayOrder",
                    method: "POST",
                    data: {
                        appid: cAPPID, mch_id: cmch_id, key: cAppSecret,
                        nonceStr: this.randomString(16), out_trade_no: res.out_trade_no
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res.data);
                    // this.$toast(res.data.err_code_des)
                    if (res.data.return_msg == 'OK') {
                        console.log('测试成功,去查询订单')
                        this.paymentResult(res)
                    } else {
                        console.log('我错了')
                        // this.$message.error(res.data.message);
                    }
                    this.loading = false;
                });
        },
        wxpayFunc(resc, OrderNo, Amount) {
            console.log('去请求后台的参数', resc);
            // this.$toast.loading({
            //     message: '生成微信订单',
            //     forbidClick: true,
            // });
            this.loading = true;
            this.title = '等待支付'
            // Toast.fail('失败文案');
            const wechatConfig = {};
            const local = window.location.href;
            let obj = {
                // AppSecret: '68682020122820210304BBC686820201',
                // appid: 'wxec50473d0c0ec298',
                // mch_id: '1608150212',
                // key: '68682020122820210304BBC686820201',
                appid: cAPPID,
                mch_id: cmch_id,
                key: cAppSecret,
                // mch_id:'1305638280',
                openid: localStorage.getItem('openid'),
                timestamp: Math.floor(new Date().getTime() / 1000),
                out_trade_no: OrderNo,
                total_fee: Amount * 100,
                nonceStr: this.randomString(16),
                body: 'JSAPI支付测试',

                // notify_url:encodeURIComponent(local),
                notify_url: local
            }
            console.log(localStorage.getItem('jspaccess_token'))
            if (localStorage.getItem('jspaccess_token') == null || localStorage.getItem('jspexpires_in') == null && Date.now() >= localStorage.getItem('jspexpires_in')) {
                // Toast('获取签名')
                // this.$toast.loading({
                //     message: '获取签名',
                //     forbidClick: true,
                // });
                this.loading = true;
                this.title = '获取签名'
                this.$axios
                    .request({
                        url: window.g.tokenBaseUrl + "/jcptoken",
                        method: "get",
                        params: {
                            APPID: cAPPID,
                            APPSECRET: cAppSecret
                        },
                    })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)
                        console.log(res.data, res.status);
                        if (res.status == '200') {
                            console.log(432423)
                            localStorage.setItem('jspaccess_token', res.data.access_token)
                            localStorage.setItem('jspexpires_in', res.data.expires_in * 1000 + Date.now())
                            // console.log(localStorage.getItem('jspaccess_token'), localStorage.getItem('jspexpires_in'))
                            this.wxpayFunc(resc, OrderNo, Amount)
                        } else {
                            console.log(432423)
                            // Toast(res.data.errmsg);

                            // this.$message.error(res.data.message);
                        }
                        this.loading = false;
                    });
            } else {
                // this.$toast('支付开始')
                this.loading = true;
                this.title = '获取签名'
                console.log(window.location.href.split('#')[0], resc)
                let that = this;
                this.$axios
                    .request({
                        url: window.g.tokenBaseUrl + "/wxsign",
                        method: "get",
                        params: {
                            ACCESS_TOKEN: localStorage.getItem('jspaccess_token'),
                            url: location.href.split('#')[0]
                            // url: window.location.protocol+window.location.host+window.location.pathname
                        },
                    })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)
                        console.log(res);
                        this.loading = false;
                        let newRes = res;
                        if (res.status == '200') {
                            console.log({
                                debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: cAPPID, // 必填，公众号的唯一标识
                                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                                nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                                signature: res.data.sign,// 必填，签名
                                jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
                            })

                            // wx.config({
                            //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            //     appId: 'wxec50473d0c0ec298', // 必填，公众号的唯一标识
                            //     timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                            //     nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                            //     signature: res.data.sign,// 必填，签名
                            //     jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
                            // });
                            function onBridgeReady(res, OrderNo) {
                                let newResc = res;
                                console.log(resc, res)
                                // 参与签名的参数为：appId、timeStamp、nonceStr、package、signType，参数区分大小写。）
                                let paySign = that.$md5(`appId=${cAPPID}&timeStamp=${res.data.timestamp}&noncestr=${res.data.noncestr}&package=prepay_id=${resc.prepay_id || resc.data.prepay_id}&signType=MD5`);
                                console.log(paySign)
                                console.log({
                                    "appId": cAPPID,     //公众号ID，由商户传入     
                                    "timeStamp": res.data.timestamp,         //时间戳，自1970年以来的秒数     
                                    "nonceStr": res.data.noncestr, //随机串     
                                    "package": "prepay_id=" + (resc.prepay_id || resc.data.prepay_id),
                                    "signType": "MD5",         //微信签名方式：     
                                    "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                })
                                WeixinJSBridge.invoke(
                                    'getBrandWCPayRequest', {
                                    "appId": cAPPID,     //公众号ID，由商户传入     
                                    "timeStamp": res.data.timestamp,         //时间戳，自1970年以来的秒数     
                                    "nonceStr": res.data.noncestr, //随机串     
                                    "package": "prepay_id=" + (resc.prepay_id || resc.data.prepay_id),
                                    "signType": "MD5",         //微信签名方式：     
                                    "paySign": paySign //微信签名  https://pay.weixin.qq.com/wiki/doc/api/jsapi.php?chapter=7_7&index=6
                                },
                                    function (res) {
                                        console.log(res)
                                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                                            // 使用以上方式判断前端返回,微信团队郑重提示：
                                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                        }
                                        // Toast('查询订单信息')
                                        that.testFindOrder(newResc, OrderNo)
                                    });
                            }
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                }
                            } else {
                                onBridgeReady(newRes, OrderNo);
                            }
                            // 注入权限验证配置
                            // wx.ready(function () {
                            // 判断当前客户端版本是否支持指定JS接口
                            // wx.checkJsApi({
                            // jsApiList: ['chooseWXPay'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                            // success: (res) => {
                            // console.log(res)
                            // 以键值对的形式返回，可用的api值true，不可用为false
                            // 如：{ "checkResult": { "chooseImage": true }, "errMsg": "checkJsApi:ok" }
                            // 发起微信支付请求
                            // wx.chooseWXPay({
                            //     timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            //     nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
                            //     package: res.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            //     signType: 'MD5', // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                            //     paySign: resp.paySign, // 微信支付签名
                            //     success: (res) => {
                            //         // 支付成功后的回调函数
                            //         _this.$router.push({
                            //             path: '/payResult',
                            //             query: {
                            //                 orderTotalPrice: _this.orderInfo.amount,
                            //                 orderNo: _this.orderInfo.orderNo,
                            //                 orderId: _this.orderInfo.orderId
                            //             }
                            //         });
                            //     },
                            //     fail: (err) => {
                            //         _this.$router.go(-1);
                            //     },
                            //     cancel: function (err) {
                            //         // 用户取消支付
                            //         _this.$router.go(-1);
                            //     },
                            // });
                            // },
                            // fail:(err)=>{
                            // console.log(err)
                            // }
                            // });
                            // });
                            wx.error(function (res) {
                                console.log('错误：', res)
                                //   // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                            });

                        } else {
                            this.$message.error(res.data.errmsg);
                            // this.$message.error(res.data.message);
                        }
                        console.log(resc)

                    });
                return false;

            }

            return false;
            //下单之前的签名信息；

            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/wxsign",
                    method: "get",
                    params: {
                        access_token: localStorage.getItem('refresh_token')
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    Toast(res.data.errmsg);
                    if (res.data.success) {
                        Toast(res.data.return_msg);
                    } else {
                        Toast(res.data.errmsg);
                        // this.$message.error(res.data.message);
                    }
                });

            return false;
            // console.log(memberId, MerchantID, OrderNo, Amount, PayType, OrderTime);
            console.log(obj, window.g.tokenBaseUrl + "/wxpay");
            this.$axios
                .request({
                    url: window.g.tokenBaseUrl + "/wxpay",
                    method: "POST",
                    data: {
                        ...obj
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res.data.return_msg);
                    Toast(res.data.return_msg);
                    if (res.data.success) {

                    } else {

                        // this.$message.error(res.data.message);
                    }
                });

            // 请求后端接口支付
            // let resp = await wxPay(params); 
            // 商户号 1608150212
            // wx.config({
            //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //     appId: 'wxf454517019e5fa8f', // 必填，公众号的唯一标识
            //     timestamp: timestamp, // 必填，生成签名的时间戳
            //     nonceStr: nonceStr, // 必填，生成签名的随机串
            //     signature: 'c7fb5ab4acc23ea397b2179b921ce8bb404303d1',// 必填，签名
            //     jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
            // });
            //         wx.error(function (res) {
            //             Toast(res.errMsg)
            //   // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
            // });
        },
        paymentResult(res, resc) {
            console.log(res)
            // 先查询 
            if (res.data.trade_state == 'SUCCESS') {
                this.$axios
                    .request({
                        url: "/api/v1/WeChat/PaymentResult",
                        method: "get",
                        params: {
                            memberId: this.memberId,
                            MerchantID: localStorage.getItem('MerchantID'),
                            OrderNo: this.orderId,
                            PayOrderNo: res.data.transaction_id,
                            Amount: this.reamount,
                            ReceiptsAmount: this.reamount,
                            PayTime: this.OrderTime,
                            CreateBy: localStorage.getItem('wechatUserID')
                        },
                    })
                    .then((res) => {
                        // this.$EventBus.$emit('asd',res)
                        console.log(res);
                        if (res.data.success) {
                            this.$message({
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getMemeberDetails();
                            this.reamount = 0;
                        } else {
                            this.$message.error(res.data.message.substring(0, 15));
                            // this.$toast(res.data.message.substring(0, 15))
                            // this.$message.error(res.data.message);
                        }

                        console.log(this.type)
                    });
            }
        },
        randomString(e) {
            e = e || 32;
            var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz",
                a = t.length,
                n = "";
            for (i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
            return n
        },
        // WeChat/PaymentResult
        // let memberId = id;
        //             let MerchantID = localStorage.getItem('MerchantID')
        //             let OrderNo = this.guid();
        //             let Amount = '0.01';
        //             let PayType = '微信';
        //             let OrderTime = this.dateFormat();
        GetDataDictionaryInfo() {
            let datac = localStorage.getItem("DictionaryInfo")

            if (datac) {
                let models = JSON.parse(datac);
                console.log(models)
                var type = ''
                models.forEach((item) => {
                    // 身份证
                    if (item.dictionaryContent == '微信') {
                        type = item;
                    }
                });
                this.type = type;
            } else {
                this.type = "";
                // this.$message.error(res.data.message);
            }
            return false;
            this.$axios
                .request({
                    url: "/api/v1/OpenApi/GetDataDictionaryInfo",
                    method: "get",
                    params: {
                        node: 999,
                        MerchantID: localStorage.getItem("MerchantID"),
                    },
                })
                .then((res) => {
                    // this.$EventBus.$emit('asd',res)
                    console.log(res);
                    if (res.data.success) {
                        var type = ''
                        res.data.models.forEach((item) => {
                            // 身份证
                            if (item.dictionaryContent == '微信') {
                                type = item;
                            }
                        });
                        this.type = type;
                    } else {
                        this.type = "";
                        // this.$message.error(res.data.message);
                    }
                    console.log(this.type)
                });
        },
        //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
        dateFormat() {
            var datec = new Date();
            var year = datec.getFullYear();
            /* 在日期格式中，月份是从0开始的，因此要加0
             * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
             * */
            var month =
                datec.getMonth() + 1 < 10
                    ? "0" + (datec.getMonth() + 1)
                    : datec.getMonth() + 1;
            var day = datec.getDate() < 10 ? "0" + datec.getDate() : datec.getDate();
            var hours =
                datec.getHours() < 10 ? "0" + datec.getHours() : datec.getHours();
            var minutes =
                datec.getMinutes() < 10 ? "0" + datec.getMinutes() : datec.getMinutes();
            var seconds =
                datec.getSeconds() < 10 ? "0" + datec.getSeconds() : datec.getSeconds();
            // 拼接
            return ''+year + month + day + hours + minutes + seconds;
        },
        //生成随机 GUID 数
        guid() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return S4() + S4() + S4() + S4() + S4() + S4();
        },
        toAddMember() {
            this.$router.push({ path: '/addmember', query: { disabled: true } });
        },
        prewImg() {
            ImagePreview([this.img]);
        },
        func(index) {
            console.log(index)
            if (index == 1) {
                this.$router.push('/orderDetail');
            }
            if (index == 5) {
                this.$router.push('/modPin');
            }
        }
    },
};
</script>
<style  scoped>
.dg div.active {
    border: 1px solid red;
}

.ds {
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.ds .van-col:first-child {
    border-right: 1px solid #ddd;
}

.ds .van-col {
    background-color: #fff;
    padding: 13px 0;
    width: 49%;
}

.ds .van-col div:first-child {
    background-color: #fff;
    padding-bottom: 10px;
}

.dg {
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.dg .van-col {
    background-color: #fff;
    width: 32%;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 5px;
}

* {
    font-size: 14px;
}

.van-cell__left-icon {
    font-size: 20px;
}

#img /deep/ img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
</style>