<template>
    <van-empty class="custom-image" :image=" img"
        description="缺少APPID或者SECRET参数,请联系管理员" />
</template>

<script>
import Vue from 'vue';
import { Empty } from 'vant';

Vue.use(Empty);
export default {
   data(){
    return {
        img:require('../static/01.png')
    }
   }
}
</script>

<style>
   .custom-image .van-empty__image {
    width: 90px;
    height: 90px;margin-bottom:10px;
  }
  .custom-image .van-empty__description{
    color: red;
  }
</style>