<template>
    <div style="">
        <!-- <van-nav-bar title="成员操作" left-text="返回" left-arrow @click-left="onClickLeft" fixed /> -->
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
            <van-field v-model="OldPinCode" name="OldPinCode" label="旧Pin码" type="password" placeholder="请输入旧Pin码"
                :rules="[{ required: true, message: '旧Pin码不能为空' }]" />
            <!-- 通过 pattern 进行正则校验 -->
            <van-field v-model="NewPinCode" name="NewPinCode" label="新Pin码" type="password"  placeholder="请输入新Pin码"
                :rules="[{ required: true, message: '新Pin码不能为空' }]" />
                <van-field v-model="NewPinCodec" name="NewPinCodec" label="确认新Pin码" type="password"  placeholder="请再次输入新Pin码"
                :rules="[{ required: true, message: '确认新Pin码不能为空' }]" />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import CryptoJS from "crypto-js";
const encry = (dataStr, key) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
import Vue from 'vue';
import { NavBar, Form, Field, RadioGroup, Radio,Toast} from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);Vue.use(Toast);
export default {
    name: 'AddMember',

    data() {
        return {
            OldPinCode: '',
            NewPinCode: '',
            MemberID:'',
            NewPinCodec:''
        };
    },

    mounted() {
let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        if (user) {
            let { id } = user;
            this.MemberID =id
        } else {
            this.getMemeberDetails();
        }
    },

    methods: {
         getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')                       
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { id} = res.data;
                        this.id= id
                    } else {
                        localStorage.setItem('user', '')
                    }
                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        onSubmit(values) {
            console.log('submit', values);
            if(this.NewPinCodec !== this.NewPinCode){
                this.$message.error('两次Pin码不一致');
                return false
            }
            this.$axios
                .request({
                    url: "/api/v1/WeChat/ModPinCode",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid'),
                        OldPinCode:encry(
                              this.OldPinCode,
                               "270AEF9A6F5A80EA68648CC0"
                         ),
                        NewPinCode:encry(
                           this.NewPinCode,
                       "270AEF9A6F5A80EA68648CC0"
                ),
                        
                         MemberID:this.MemberID
                    },
                })
                .then((res) => {
                    console.log(res,res.data.message);
                    if (res.data.success) {
                        //赋值  
                        //  this.$message.success(res.data.message);    
                           let url= window.location.origin+window.location.pathname+window.location.search+'/#/index';
                           window.location.replace(url)
                    } else {
                        // Toast.loading(res.data.message)
                         this.$message.error(res.data.message);
                    }

                }).catch((err)=>{
                    Toast(err)
                })
            
        },
    },
};
</script>
<style  scoped>
* {
    font-size: 14px !important;
}
</style>