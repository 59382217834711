<template>
    <div style="">
        <van-cell-group inset style="padding-top: 10px;">
            <van-cell title="姓名" :value="obj.memberName" />
            <van-cell title="用户编号" :value="obj.memberNo" />
            <van-cell title="电话号码" :value="obj.phone" />
            <van-cell title="证件号码" :value="obj.idno" />
            <van-cell title="余额" :value="'￥' + obj.balance" />
            <van-cell title="注册时间" :value="obj.accountTime" />
            <van-cell title="到期时间" :value="obj.period" />
            <!-- <van-cell title="openID" :value="obj.openIDFri">
                <template #right-icon>
                    <van-icon name="search" class="search-icon" />
                </template>
            </van-cell> -->
        </van-cell-group>
    </div>
</template>
<script>
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';

Vue.use(Cell);
Vue.use(CellGroup);
export default {
    name: 'Userinfo',

    data() {
        return {
            obj: {}
        };
    },

    mounted() {
        this.getMemeberDetails()
    },

    methods: {
        getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, balance, id } = res.data;
                        this.obj = res.data;

                    } else {
                        localStorage.setItem('user', '')
                        this.$message({
                            message: res.data.message,
                            type: 'warning'
                        });
                    }


                });
        },
    },
};
</script>
<style lang="" scoped>
    
</style>