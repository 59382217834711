<template>
    <div>
        <!-- <van-nav-bar title="个人中心" fixed /> -->
        <div style="background-color: rgb(239 238 238);height:100vh;">
            <div style="position: relative;overflow: hidden;">
                <div style="background-color:  #9fdec9;height:130px;width:100%;"></div>
                <div style="background-color: rgb(239 238 238);height:130px;width:100%;"></div>
                <div 
                    style="position:absolute;text-align: center;width:94%;margin-left:3%;top:20px;background-color: #fff;height:200px;border-top-left-radius: 5px;border-top-right-radius: 5px;padding-top:10%;">
                    <van-image id="img" width="100" height="100" :src="img"
                        @click='prewImg' />
                    <p @click="toAddMember">{{ userName }}</p>
                </div>
            </div>
            <van-cell-group inset style="margin:10px 12px;">
<!--                <van-cell title="个人信息" @click="func(8)" icon="user-circle-o" is-link />-->
<!--                <van-cell title="上传人脸照片" @click="func(9)" icon="user-circle-o" is-link />-->
                <van-cell title="我的订单" @click="func(1)" icon="orders-o" is-link />
                <van-cell title="我的会员" @click="createQRcode_Verify" icon="orders-o" is-link />
<!--                <van-cell title="充值记录" @click="func(7)" icon="cash-back-record" is-link />-->
<!--                <van-cell title="解绑" @click="func(2)" icon="friends-o" is-link />-->
<!--                <van-cell title="修改个人PIN码" @click="func(5)" icon="user-circle-o" is-link />-->
                <!-- <van-cell title="绑定PIN码" @click="func(6)" icon="user-circle-o" is-link /> -->
                
            </van-cell-group>
            <!-- <van-cell-group inset style="margin:10px 12px;">
                <van-cell title="我的客服" @click="func(3)" icon="phone-o" is-link />
                <van-cell title="设置" @click="func(4)" icon="setting-o" is-link />
            </van-cell-group> -->
        </div>
        <van-dialog v-model="show5" title="会员码">
            <div id="rfd" style='display: flex;justify-content: center;padding:10px 0;width:60%;    margin-left: 20%;'>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import {Image as VanImage, Cell, CellGroup, NavBar, ImagePreview, Toast} from 'vant';
import QRCode from "qrcodejs2";
Vue.use(VanImage);
Vue.use(Cell);
Vue.use(NavBar);
Vue.use(CellGroup);
export default {
    name: 'yueOrder2',

    data() {
        return {
            userName: '',
            img:'',
            show5:false
        };
    },

    mounted() {
        // console.log(window.location.hostname)
         let img = localStorage.getItem('img');
         this.img=img;
        let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
        if (user) {
            let { memberName, phone } = user;
            this.userName = memberName + ' / ' + phone;
        }

    },

    methods: {
        toAddMember() {
            // this.$router.push({ path: '/addmember', query: { disabled: true } });
        },
        prewImg() {
            ImagePreview([this.img]);
        },
        createQRcode_Verify() {
            // this.$axios
            //     .request({
            //         url: "/api/v1/OpenApi/GetMemberInfo",
            //         methods: "get",
            //         params: {
            //             MerchantID: localStorage.getItem("merchantID"),
            //             MemberNo: localStorage.getItem("openid"),
            //             MemberName: "",
            //             CardNO: "",
            //             IDNO: "",
            //             Phone: "",
            //             MemberTypeID: "-1" ,
            //             Orderby: "",
            //             StartIndex: 0,
            //             EndIndex: 99,
            //         },
            //     }).then((res) => {
            //         if(res.data.success){
                        this.qrCodeTitle = '核销码'
                        this.show5 = true;
                        this.$nextTick(() => {
                            var tc = document.getElementById('rfd');
                            tc.innerHTML = '';
                            var qrcode = new QRCode('rfd', {
                                text: localStorage.getItem("openid"),
                                width: 168,
                                height: 168,
                                colorDark: '#000000',
                                colorLight: '#ffffff',
                                correctLevel: QRCode.CorrectLevel.H
                            });
                        })

            //         }else {
            //             Toast.fail({duration:2000,message:'未购买会员卡'})
            //         }
            // }).catch(err => {
            //     Toast.fail({duration:2000,message:err.message})
            // })

        },
        func(index) {
            console.log(index)
            if (index == 1) {
                this.$router.push('/orderDetail');
            }
            if (index == 2) {
                this.$router.push({ path: '/addmember',query: { disabled:false,unbind:2 } });
            }
            if (index == 5) {
                this.$router.push('/modPin');
            }
            if (index == 6) {
               this.$router.push({ path: '/addmember',query: { disabled:false,unbind:3 } });
            }
            if (index == 7) {
               this.$router.push({ path: '/rechargeInfo'});
            }
            if (index == 8) {
               this.$router.push({ path: '/userinfo'});
            }
            if (index == 9) {
               this.$router.push({ path: '/userface'});
            }
        }
    },
};
</script>
<style  scoped>
* {
    font-size: 14px;
}

.van-cell__left-icon {
    font-size: 20px;
}

#img /deep/ img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
</style>