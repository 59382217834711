<template>
    <div style="padding-top: 46px;overflow: hidden;width:100%;">
        <van-nav-bar title="会员注册" left-text="返回" left-arrow @click-left="onClickLeft" fixed />
        <van-form validate-first @failed="onFailed" @submit="onSubmit" :disabled='!!disabled'>
            <!-- <van-field v-model="memberNo" name="memberNo" label="用户编号" placeholder="请输入用户编号"
                :rules="[{ required: true, message: '用户编号不能为空' }]" /> -->
            <!-- 通过 pattern 进行正则校验 -->
            <van-field v-model="memberName" name="memberName" label="用户名" placeholder="请输入用户名"
                :rules="[{ required: true, message: '用户名不能为空' }]" />
            <van-field v-model="pinCode" type="password" name="pinCode" label="密码" placeholder="请输入密码"
                :rules="[{ required: true, message: '请填写密码' }]" />
            <van-field v-model="pinCode1" type="password" name="pinCode1" label="确认密码" placeholder="请输入确认密码"
                :rules="[{ required: true, message: '请确认密码' }]" />
            <van-field v-model="phone" name="phone" label="电话号码" placeholder="请输入电话号码"
                :rules="[{ validator1, required: true, message: '请输入正确的电话号码' }]" />
            <!-- <van-field v-model="PinCode" name="PinCode" label="Pin码" placeholder="请输入Pin码"
                :rules="[{ required: true, message: 'Pin码不能为空' }]" /> -->
            <!-- 通过 validator 进行函数校验 -->
            <van-field v-model="idno" name="idno" label="身份证" placeholder="请输入身份证"
                :rules="[{ validator, required: true, message: '身份证格式错误' }]" />

            <van-field name="sex" label="性别">
                <template #input>
                    <van-radio-group v-model="sex" direction="horizontal" :disabled='!!disabled'>
                        <van-radio name="0">男</van-radio>
                        <van-radio name="1">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 16px;">

                <van-button round block type="info" native-type="submit">会员注册</van-button>
            </div>
        </van-form>

    </div>
</template>
<script>
import Vue from 'vue';
import { NavBar, Form, Field, RadioGroup, Radio, Toast } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field); Vue.use(Toast);
import CryptoJS from "crypto-js";
const encry = (dataStr, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.TripleDES.encrypt(dataStr, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};
export default {
    name: 'AddMember',
    data() {
        return {
            disabled: false,
            memberName: '',
            memberNo: '',
            idno: '', //230182198012251659
            sex: '1',
            pinCode: '',
            pinCode1: '',
            phone: '',
            pattern: /\d{6}/,
            status: 0
        };
    },

    mounted() {
        // this.getMemeberDetails();
        console.log(this.$route.query.disabled)
        this.disabled = this.$route.query.disabled;
        this.status = this.$route.query.unbind;
        console.log(this.$route.query.unbind)
        if (this.disabled == 'false') {
            console.log(342334)
            this.disabled = !!''
        } else {
            console.log(342334)
            this.disabled = !''
        }
        console.log(this.disabled, !!this.disabled)
        if (this.disabled || this.status == 2) {
            let user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
            console.log(user)
            if (user) {
                let { memberName, phone, memberNo, idno, sex } = user;
                this.memberName = memberName;
                this.phone = phone;
                this.memberNo = memberNo;
                this.idno = idno;
                this.sex = sex;
            } else {
                this.getMemeberDetails();
            }
        } else {
            // this.status=0;
        }
    },

    methods: {
        getMemeberDetails() {
            this.$axios
                .request({
                    url: "/api/v1/WeChat/GetMemberDetails",
                    methods: "get",
                    params: {
                        merchantID: localStorage.getItem('MerchantID'),
                        openID: localStorage.getItem('openid')
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        localStorage.setItem('user', JSON.stringify(res.data))
                        let { memberName, phone, memberNo, idno, sex } = res.data;
                        console.log(memberName, phone, memberNo, idno, sex)
                        this.memberName = memberName;
                        this.phone = phone;
                        this.memberNo = memberNo;
                        this.idno = idno;
                        this.sex = sex;
                    } else {
                        localStorage.setItem('user', '')
                    }
                    //临时
                    // let { memberName, phone, memberNo, idno, sex } = res.data;
                    // this.memberName = memberName;
                    // this.phone = phone;
                    // this.memberNo = memberNo;
                    // this.idno = idno;
                    // this.sex = sex;

                });
        },
        onClickLeft() {
            console.log('返回');
            this.$router.back();
        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        validator(val) {
            val = val.replace(' ', '')
            return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val);
        },
        // 异步校验函数返回 Promise
        validator1(val) {
            return /^1([358][0-9][4[579]|66|7[0135678]|9[89])[8-9]{8}$/.test(val);
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        onSubmit(values) {
            console.log('submit', values);
            let obj = {
                phone: this.phone,
                PinCode: this.PinCode,
                memberName: this.memberName,
                phone: this.phone,
                // memberNo: this.memberNo,
                idno: this.idno,
                sex: this.sex,
            }
            console.log({ ...obj, MerchantID: localStorage.getItem('MerchantID'), openID: localStorage.getItem('openid') ,PinCode: encry(this.PinCode,"270AEF9A6F5A80EA68648CC0")})

            if(this.pinCode.replace(' ','') !== this.pinCode1.replace(' ','')){
                this.$message.error('两次密码输入不一致，请确认');
                return false;
            }

            console.log('检测2次密码是否一次')
            // return false;
            this.$axios
                .request({
                    method: "POST",
                    url: "/api/v1/WeChat/MemberReg",
                    data: { ...obj, MerchantID: localStorage.getItem('MerchantID'), openID: localStorage.getItem('openid') ,PinCode: encry(
                        this.PinCode,
                            "270AEF9A6F5A80EA68648CC0"
                        )},
                })
                .then((res) => {
                    // this.$EventBus.$emit("asd", {
                    //     ...res,
                    //     title: "操作员信息",
                    //     ctx: "删除" + (row.merchantName || ""),
                    // });
                    let url = window.location.origin + window.location.pathname + window.location.search + '/#/index';
                    console.log(url, res)
                    //   window.location.replace(url)
                    if (res.data.success) {
                        this.$message.success(res.data.message);
                        //  setTimeout(()=>{
                        // this.$router.back();
                        //  let url= window.location.origin+window.location.pathname+window.location.search+'/#/index';
                        //  },1000)

                        //  console.log(url)
                        window.location.replace(url)
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
    },
};
</script>
<style  scoped>
* {
    font-size: 14px !important;
}
</style>