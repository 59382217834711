import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    istrc: false, //是否隐藏路由,
    statec:-1,
  },
  getters: {
    istrc:state=>state.istrc
  },
  mutations: {
    SETUSERINFO(state,data){
      console.log(data)
      state.istrc=data.t;
      if(data.path=='/regref'){
        state.statec=1
      }
      if(data.path=='/index'){
        state.statec=0
      }
      if(data.path=='/peopleCenter'){
        state.statec=2
      }
    }
  },
  actions: {
    GETUSERIONFO({commit},a){
      console.log(a)
      commit('SETUSERINFO', a)
    }
  },
  modules: {
  }
})
